import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "./AppContext";
import {
  Icon,
  Label,
  List,
  ListItem,
  Loader,
  Modal,
  TransitionablePortal,
} from "semantic-ui-react";
import $data from "../services/$data";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import olMap from 'ol/Map';
import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import WKT from 'ol/format/WKT'
import Feature from "ol/Feature";
import { Point } from "ol/geom";
const dotColors = {
  0: 'red',
  1: 'green',
  2: 'blue',
  3: 'orange',
  4: 'purple'
}

function Stats(props) {
  const mapRef = useRef(null);

  const { stats, onUpdateState } = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);

  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);

  const [mapMount, setMapMount] = useState(false);

  const initMap = () => {
    setTimeout(() => {
      const features = [];

      data.filter((obj, i) => i <= 4).map((l, i) => {
        l.location.map(wkt => {
          let coordinates = wkt.split('(')[1].split(')')[0].split(' ').map(v => parseFloat(v)).reverse()
          let feat = new Feature(new Point(fromLonLat(coordinates)));

          feat.setProperties({
            dotKey: i
          })

          features.push(feat);
        })
      })

      const dots = new VectorImageLayer({
        style: (f) => new Style({
          image: new CircleStyle({
            fill: new Fill({
              color: '#fff'
            }),
            stroke: new Stroke({
              width: 3,
              color: dotColors[f.get('dotKey')]
            }),
            radius: 3
          })
        }),
        source: new VectorSource({
          features
        })
      })
      const mp = new olMap({
        target: mapRef.current,
        layers: [
          new TileLayer({
            preload: Infinity,
            className: 'ol-layer ol-grayscale',
            source: new OSM()
          }),
          dots
        ],
        interactions: [],
        view: new View({
          zoom: 4,
          center: fromLonLat([20, 40])
        })
      });

      mp.getView().fit(dots.getSource().getExtent(), { padding: [20, 20, 20, 20] })
    })

  }


  const getAllData = async () => {
    try {
      let viewStats = await $data.getViewStats()
      let queryStats = await $data.getUsageStats()
      setData(queryStats);
      setViewData(viewStats);
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
   
    getAllData()

  }, []);

  useEffect(() => {
    if (data.length > 0 && mapMount === false && mapRef.current) {
      setMapMount(true);
      initMap()
    }
  }, [data.length])

  const renderMap = () => {
    return (
      <div ref={mapRef} style={{ width: "100%", height: "250px" }} ></div>
    );
  };

  const renderLayers = () => {
    return (
      <div style={{ width: '100%', display: 'flex', direction: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <List>
          {data.filter((obj, key) => key <= 4).map((layer, key) => {
            let obj = $data.getLayerObjectByFilename(layer.name)
            return <ListItem key={key} header={obj.title} icon={<Icon color={dotColors[key]} name="dot circle"></Icon>}></ListItem>;
          })}
        </List>
      </div>
    );
  };

  const renderViewLayers = () => {
    return (
      <div style={{ width: '100%', display: 'flex', direction: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <List>
          {viewData.filter((obj, key) => key <= 4).map((layer, key) => {
            return <ListItem key={key} header={layer.layer}></ListItem>;
          })}
        </List>
      </div>
    );
  };


  const renderContent = () => {
    if (loading) return null;

    // if(error) return 'ERROR';

    // if(data.length === 0) return 'NO STATS';

    return (
      <div>
        <div style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: '20px'
            }}> 
        <div>
          <h3>Most queried datasets</h3>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {renderLayers()}
          </div>
        </div>
        <div>
          <h3>Most viewed datasets</h3>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {renderViewLayers()}
          </div>
        </div>
      </div>
      { renderMap() }
      </div>
        
    );
};

return (
  <TransitionablePortal
    open={stats}
    transition={{ animation: "fade up", duration: 500 }}
  >
    <Modal size="large" open={stats} onClose={() => onUpdateState({ stats: false })}>
      <Modal.Header
        style={{
          borderRadius: 0,
          color: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="background-primary"
      >
        OpenLandMap Usage Statistics
        <Icon
          onClick={() => onUpdateState({ stats: false })}
          name="times"
          style={{ color: "#fff", cursor: "pointer" }}
        />
      </Modal.Header>

      <Modal.Content>
        <div style={{ minHeight: "150px" }}>
          {loading && (
            <Loader className="olm primary-loader" size="big" active />
          )}

          {renderContent()}
        </div>
      </Modal.Content>
    </Modal>
  </TransitionablePortal>
);
}

export default Stats;
