import { useRef, useState } from "react";
import { Button, Icon, Popup, TextArea } from "semantic-ui-react";
import TabHeader from "./TabHeader";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";

function ShareTab(props) {
	const textAreaRef = useRef(null);
	const [text, setText] = useState('');
	const element = textAreaRef?.current?.ref?.current;

	const generateURL = () => {
		setText(window.location.href);
	}

	const generateHTML = () => {
		const url = window.location.href;

		setText(`<iframe style="min-height: 650px" width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="${url}"></iframe>`);
	}
	


	return (
		<div className="olm tabs-container credits-container text-center">
			<TabHeader title="Share" />
			<div className="tab-content text-left" style={{padding: '10px'}}>

				<p>Generate URL/Embedded HTML</p>
				<TextArea className="text-primary" ref={textAreaRef} onClick={() => {if(element) {element.focus();  element.select()}}} value={text} rows={10} style={{width: '100%'}} readOnly></TextArea>

				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
					<Popup position="bottom left" trigger={<Icon className="text-primary" onClick={generateURL} style={{cursor: 'pointer'}} name="attach" />} content="Generate URL"></Popup>	
					<Popup position="bottom right" trigger={<Icon className="text-primary" onClick={generateHTML} style={{cursor: 'pointer'}} name="code" />} content="Generate HTML"></Popup>	
				
				</div>

				
				<p>Share via social networks</p>

				<div style={{marginTop: '25px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
					<FacebookShareButton url={window.location.href}>
						<Icon name="facebook" style={{color: '#0B84EE', fontSize: '40px'}} />
					</FacebookShareButton>

					<TwitterShareButton url={window.location.href}>
						<Icon name="twitter" style={{color: '#1DA1F2', fontSize: '40px'}} />
					</TwitterShareButton>

					<LinkedinShareButton url={window.location.href}>
						<Icon name="linkedin" style={{color: '#0077B5', fontSize: '40px'}} />
					</LinkedinShareButton>
				</div>

				<div style={{marginTop: '15px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
					<WhatsappShareButton url={window.location.href}>
						<Icon name="whatsapp" style={{color: '#24CC63', fontSize: '40px'}} />
					</WhatsappShareButton>

					<ViberShareButton url={window.location.href}>
						<Icon name="viber" style={{color: '#6F5DEA', fontSize: '40px'}} />
					</ViberShareButton>

					<TelegramShareButton url={window.location.href}>
						<Icon name="telegram" style={{color: '#2C9FD7', fontSize: '40px'}} />
					</TelegramShareButton>
				</div>

			</div>
		</div>
	)
}

export default ShareTab;