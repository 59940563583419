import {
  CategoryScale,
  Chart,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Tooltip,
	Legend
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { Icon, Modal, TransitionablePortal } from "semantic-ui-react";
import * as dateAdapter from "chartjs-adapter-moment";

Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
	Legend
);

function TimeSeries({ open, onClose, data, layerObj }) {
  console.log(data);
  console.log(layerObj);

  const formatDatasets = () => {
    if (layerObj.type === "both") {
			console.log(data.data.filter((obj, key) => data.data.length / 2 < key).map(obj => ({...obj, x: obj.x.split('(')[0]})))
      return [
        {
					label: '30cm',
          pointRadius: 1,
          borderColor: "green",
					pointBackgroundColor: 'green',
          data: data.data.filter((obj, key) => data.data.length / 2 < key + 1).map(obj => ({...obj, x: obj.x.split('(')[0]})),
        },
        {
					label: '0cm',
          pointRadius: 1,
          borderColor: "darkorange",
					pointBackgroundColor: 'darkorange',
          data: data.data.filter((obj, key) => data.data.length / 2 > key).map(obj => ({...obj, x: obj.x.split('(')[0]})),
        },
      ];
    }

    return [
      {	
				label: layerObj.title,
        pointRadius: 1,
        borderColor: "darkorange",
				pointBackgroundColor: 'darkorange',
        data: data.data,
        spanGaps: true
      },
    ];
  };

  return (
    <TransitionablePortal
      open={open}
      transition={{ animation: "fade up", duration: 500 }}
    >
      <Modal open={open} onClose={onClose}>
        <Modal.Header
          style={{
            borderRadius: 0,
            color: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="background-primary"
        >
          {layerObj.title}
          <Icon
            onClick={onClose}
            name="times"
            style={{ color: "#fff", cursor: "pointer" }}
          />
        </Modal.Header>

        <Modal.Content style={{ minHeight: "360px" }}>
          <Line
            data={{
              datasets: formatDatasets(),
            }}
            options={{
              maintainAspectRatio: false,
              animation: { duration: 0 },
              interaction: {
                mode: "index",
                intersect: "false",
              },
              plugins: {
                legend: {
                  labels: {
                    usePointStyle: true,
                    color: "#555",
                    font: {
                      weight: "bold",
                    },
                  },
                },
                tooltip: {
                  enabled: true,
                  mode: "index",
                  intersect: false,
                },
              },
              scales: {
                x: {
                  offsetAfterAutoskip: true,
                  ticks: {
                    color: "#555",
                    font: {
                      weight: "bold",
                    },
                  },
                },
                y: {
                  ticks: {
                    color: "#555",
                    font: {
                      weight: "bold",
                    },
                    // callback: (tickValue) => {
                    //   return tickValue + ` ${DataService.getUnitForLayer(state.layer)}`
                    // }
                  },
                },
              },
            }}
          />
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
}

export default TimeSeries;
