import { useContext } from "react"
import { AppContext } from "../AppContext"
import Toolbar from "../toolbar/Toolbar";
import CreditsTab from "./tabs/CreditsTab";
import GitlabTab from "./tabs/GitlabTab";
import LayerDetailsTab from "./tabs/LayerDetailsTab";
import LayersTab from "./tabs/LayersTab";
import OpenGeoHubNewsTab from "./tabs/OpenGeoHubNewsTab";
import ShareTab from "./tabs/ShareTab";
import TwitterTab from "./tabs/TwitterTab";
import YoutubeTab from "./tabs/YoutubeTab";

function Sidebar(props) {
  const {sidebar, activeTab, onUpdateState} = useContext(AppContext);

  const renderTab = () => {
    switch(activeTab) {
      case 0: return <LayersTab />;
      case 1: return <LayerDetailsTab />;
      case 2: return <GitlabTab />;
      case 3: return <OpenGeoHubNewsTab />;
      case 4: return <TwitterTab />;
      case 5: return <CreditsTab />;
      case 6: return <ShareTab />;
      case 7: return <YoutubeTab />;
      default: return null
    }
  }

  return (
    <div className={"olm sidebar-container" + (sidebar ? ' open' : '')}>
      {renderTab()}
      {<Toolbar mobile />}
    </div>
  )
}

export default Sidebar