import { useContext } from "react";
import { Icon } from "semantic-ui-react";
import { AppContext } from "../../AppContext";

function TabHeader({title}) {
  const {onUpdateState} = useContext(AppContext);

  return (
    <div className="tab-header">
      {title}
      <Icon onClick={() => onUpdateState({sidebar: false})} name="times" style={{color: '#fff'}} />
    </div>
  )
}

export default TabHeader;