import { useContext, useEffect } from "react";
import { Icon, Loader, Popup } from "semantic-ui-react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";
import Response from "../helper/Response";
import useMergeState from "../hooks/useMergeState";

function Legend(props) {
	const {layer, layer2, legend} = useContext(AppContext);

	const activeLayer = props.secondary ? layer2 : layer;

	const [state, setState] = useMergeState({data: [{color: 'transparent', value: <Icon className="text-danger" name="exclamation circle" />}], loading: true})

	useEffect(() => {
		$data.getLegend(activeLayer)
			.then(data => { setState({data, loading: false}); })
			.catch(err => { setState({loading: false}) })
	},[])

	useEffect(() => {
		setState({loading: true})
		$data.getLegend(activeLayer)
			.then(data => { setState({data, loading: false}); })
			.catch(err => { setState({loading: false, data: [{color: 'transparent', value: <Icon className="text-danger" name="exclamation circle" />}]}) })
	},[props.secondary ? layer2 : layer])

	const renderContent = () => {
		return (
			<Response loading={state.loading} response={state.data}>
				{state.data.map((obj, key) => {
					return <Popup position="left center" key={key} content={obj.label} trigger={<div className="legend-color" style={{background: obj.color}}>{obj.value}</div>} />
				})}
			</Response>
		)
	}
	
	return (
		<div className={"olm legend-container" + (legend ? ' open' : ' closed') + (props.secondary ? ' is-second' : '')}>
			{state.data[0]?.units && <Popup position="left center" content={state.data[0]?.units} trigger={<div className="legend-units">{state.data[0]?.units}</div>} />}
			<div className="legend-colors-container">
				{renderContent()}
			</div>
		</div>
	)
}

export default Legend;