import { useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";

const createOptions = (layers) => {
  return layers.map(layer => {
    return {
      key: layer.shortName,
      value: layer.shortName,
      text: layer.title
    }
  })
}

function LayerSelector({ secondary }) {
  const { onUpdateState, layer, layer2 } = useContext(AppContext);

  const activeLayer = secondary ? layer2 : layer;

  const onChange = (evt, data) => {
    const layerObj = $data.getLayerObject(data.value);

    // onUpdateState({
    //   animation: false,
    //   [secondary ? 'layer2' : 'layer']: layerObj.shortName,
    //   [layerObj.paramDisplayName + (secondary ? '2' : '')]: layerObj.paramDefaultValue
    // })

    onUpdateState(Object.assign(
      {
        animation: false,
        [secondary ? 'layer2' : 'layer']: layerObj.shortName,
        [layerObj.paramDisplayName + (secondary ? '2' : '')]:
          layerObj.paramDefaultValue
      },  
      layerObj.type === 'both' ? { [secondary ? `depth2`: 'depth']: '_s_' } : {}));
  }

  return (
    <div className={"olm layer-selector" + (secondary ? ' is-second' : '')}>
      <Dropdown
        placeholder="Select layer"
        fluid
        search
        selection
        value={activeLayer}
        onChange={onChange}
        options={createOptions($data.layers)} />
    </div>
  )
}

export default LayerSelector;