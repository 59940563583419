import { Button } from "semantic-ui-react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";
import RangeSlider from 'rc-slider';

function Depth(props) {


  const layerObj = $data.getLayerObject(props.layer);

  let index = layerObj.range.indexOf(props.depth);
  

  const getMarkPosition = (i) => {
    if(i === 0) return 0;

    if(i === 1) return 10;

    if(i === 2) return 30;

    if(i === 3) return 60;

    if(i === 4) return 100;

    if(i === 5) return 200;
  }

  let marks = {};

  layerObj.range.map((v, i) => {
    marks[getMarkPosition(i)] = layerObj.rangeDisplay[i]
  });

  const renderMobile = () => {
    return (
      <div className="depth-slider-component-mobile">
        {layerObj.rangeDisplay.map((name, key) => {
          return <Button key={key} className={key === index ? 'active' : ''} onClick={() => props.onUpdateState({[props.secondary ? 'depth2' : 'depth']: layerObj.range[key]})}>{name}</Button>
        })}
      </div>
    )
  }

  return (
    <>
    {renderMobile()}
    <div className="depth-slider-component">
     <RangeSlider 
        vertical
        reverse
        min={0}
        max={getMarkPosition(layerObj.range.length - 1)}
        step={null}
        marks={marks}
        value={Object.keys(marks)[index]}
        dots
        onChange={(value) => {
          props.onUpdateState({[props.secondary ? 'depth2' : 'depth']: layerObj.range[Object.keys(marks).indexOf(value.toString())]})
        }}
         />
    </div>
    </>
  )
}

export default Depth;