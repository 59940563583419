import { Loader } from "semantic-ui-react";

function Response({response, loading, noData, error, children}) {
	if(loading) return <Loader active />;

	if(!response) return <p className="text-danger text-center">Something went wrong!</p>

	if(error) {
		return <p className="text-danger text-center">{error}</p>
	}

	if(typeof response === 'object') {
		if(Object.keys(response).length === 0) {
			return <p className="text-center">{noData}</p>
		}
	}

	if(typeof response === 'array') {
		if(response.length === 0) {
			return <p className="text-center">{noData}</p>
		}
	}
	
	return children
}

export default Response;