import { useContext } from "react"
import { Icon, Table } from "semantic-ui-react";
import $data from "../../../services/$data";
import { AppContext } from "../../AppContext"
import TabHeader from "./TabHeader"

function LayerDetailsTab(props) {
	const {layer} = useContext(AppContext);
	const layerObj = $data.getLayerObject(layer);

	return (
		<div className="olm tabs-container layer-details-container">
			<TabHeader title="Layer details"></TabHeader>
			<div style={{padding: '10px'}}>
				<p className="title">{layerObj.title}</p>
				<p className="description">{layerObj.description}</p>

				<Table>
					<Table.Body>
						<Table.Row>
							<Table.Cell className="text-primary">Resolution</Table.Cell>
							<Table.Cell>{layerObj.resolution}</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell className="text-primary">Version</Table.Cell>
							<Table.Cell>{layerObj.version}</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Body>
						<Table.Row>
							<Table.Cell className="text-primary text-bold" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>Read more <a target="_blank" href={layerObj.moreInfo}><Icon name="file" /></a></Table.Cell>
						</Table.Row>
						{/* <Table.Row>
							<Table.Cell className="text-primary text-bold" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >Download <a target="_blank" href={layerObj.download}><Icon name="download" /></a></Table.Cell>
						</Table.Row> */}
						<Table.Row>
							<Table.Cell className="text-primary text-bold" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >Metadata & Download <a target="_blank" href={layerObj.metadata}><Icon name="database" /></a></Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</div>
		</div>
	)
}

export default LayerDetailsTab