import axios from 'axios';

class $interceptors {
	_default(err) {
		return {
			status: err.response.status,
			message: err.response.data,
			statusText: err.response.statusText
		}
	}

	_network() {
		return {
			status: -999,
			message: {
				non_field_errors: ['Service is down']
			},
			statusText: 'NetworkError'
		}
	}

	_unauthorized(err) {

		return this._default(err);
	}

	_internal(err) {
		return {
			status: err.response.status,
			message: {
				non_field_errors: 'Error occured on the server'
			},
			statusText: err.response.statusText
		}
	}

	_not_found(err) {
		return {
			status: 404,
			message: {
				non_field_errors: ['Not found']
			},
			statusText: err.response.statusText
		}
	}

	_cancel(err) {
		return {
			status: -998,
			message: 'cancel',
			statusText: 'Canceled'
		}
	}

	init() {
		axios.interceptors.response.use(
			response => response, 
			err => {

				if(!err.response) {
					if(err.message === 'cancel' || err.message === 'canceled') return Promise.reject(this._cancel(err))

				// NETWORK ERROR - SERVICE IS DOWN, CORS ISSUES, INTERNET CONNECTION...
				return Promise.reject(this._network());

			} else {

				// INTERNAL SERVER ERROR
				if(err.response.status === 500) {
					return Promise.reject(this._internal(err));
				}

				if(err.response.status === 404) {
					return Promise.reject(this._not_found(err))
				}

				if(err.response.status === 401) {
					return Promise.reject(this._unauthorized(err))
				}

				// DEFAULT ERROR
				return Promise.reject(this._default(err));
			}
		});
	}

}

export default new $interceptors();

