import axios from "axios";
import _, { pickBy } from "lodash";
import moment from "moment";
import { themes } from "../config/defaults";
import URL from "../config/urls";

import parser from 'xml2json-light';
import { XMLParser } from "fast-xml-parser";
import { fromLonLat } from "ol/proj";
import { backup } from "../config/backup";

let querySource = axios.CancelToken.source();

let layersSource = axios.CancelToken.source();


class $data {

	constructor() {
		this.layers = [];
	}

	cancelQuery() { querySource.cancel('cancel'); layersSource = axios.CancelToken.source();}
	
	cancelLayers() { layersSource.cancel('cancel'); layersSource = axios.CancelToken.source(); }

	formatLayerType(code, range) {
		if(range !== 'single' && range !== undefined && (code === 'TS_N' || code === 'TS_F') ) {
			let test =range.split(',').every(v => {
				try {
					return !isNaN(parseInt(moment(v).format('YYYY')))

				} catch(e) {
					return false
				}
			})

			if(!test) return 'YEAR-SPECIFIC';
			
		}
		
		switch(code) {
			case '2D_N': return 'SINGLE';
			case 'TS_F': return 'YEAR';
			case 'TS_N': return 'YEAR';
			case 'SS_N': return 'MONTH';
			case '3D_N': return 'DEPTH';
			default: return 'SINGLE'


		}
	}

	formatLayerUnits(units) {
		switch(units) {
			case 'fraction': return '%';
			case 'index': return '';
			case 'factor': return '';
			case 'class': return '';
			case 'radians': return 'rad'; 
			case 'probability': return '%';
			default: return units
		}
	}

	formatLayerParams(obj) {
		let type = this.formatLayerType(obj.layer_display_type, obj.range);
		
		let range = obj.range.split(',');

		let params = {};

		if(type === 'DEPTH') {
			let formatedRange = range
				.map(d => parseInt(d.split('..')[1]))
				.sort((a,b) => a - b)
				.map(n => `b${n}..${n}cm`)

				params.paramGSName = 'DIM_DEPTH';
			params.paramDisplayName = 'depth';
			params.paramDefaultValue = formatedRange[0];
			params.range = formatedRange;
			params.rangeDisplay = formatedRange.map(d => d.split('..')[1])
		}

		if(type === 'YEAR-SPECIFIC') {
			let formatedRange = range
				.map(y => {
					if(y.indexOf('BC') > -1) {
						let val = parseInt(y.replace('BC', '-'));
						return val.toString();
					}

					return y
				})
				.sort((a, b) => parseInt(a) < parseInt(b) ? -2 : 2)
				.map(v => {
					if(parseInt(v) < 0) {
						return `BC${v * (-1)}`
					}

					return v
				})


			params.paramGSName = 'DIM_YEAR';
			params.paramDisplayName = 'time';
			params.paramDefaultValue = formatedRange[formatedRange.length - 1];
			params.range = formatedRange;
			params.rangeDisplay = formatedRange.map(v => v);
		}

		if(type === 'YEAR') {
			params.paramGSName = 'TIME';
			params.paramDisplayName = 'time';
			params.paramDefaultValue = range[range.length - 1];
			params.range = range;
			params.rangeDisplay = range.map(v => v)
		}

		if(type === 'MONTH') {
			params.paramGSName = 'DIM_MONTH';
			params.paramDisplayName = 'time';
			params.paramDefaultValue = 'jan';
			params.range = ['feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan'];
			params.rangeDisplay = ['February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January'];
		}

		


		return params
	}

	formatQueryURL(obj, type) {
		// console.log(obj)
		// if(type === 'horizontal') {
		// 	obj.layer_filename_pattern = obj.layer_filename_pattern.replace('*', '.*')
		// }
		if(type === 'single') {
			return `${URL.QUERY}?lat=__LAT__&lon=__LON__&coll=&mosaic=false&oem=${obj.gs_name.split(':')[0] === 'oem'}&regex=${obj.layer_filename_pattern}`
		}

		return `${URL.QUERY}?lat=__LAT__&lon=__LON__&coll=${obj.gs_name.split(':')[1]}&mosaic=true&oem=${obj.gs_name.split(':')[0] === 'oem'}&regex=${'*.tif'}`
	}

	formatLayerDimensionName(type, isSecond) {
		if(type === 'single') return null;

		if(type === 'circular') return 'time';

		if(type === 'vertical') return 'depth';

		if(type === 'horizontal') return 'time';

		if(type === 'both') return isSecond ? 'depth' : 'time';

		return 'time'
	}

	formatRange(range, isDual, isSecond, type, depths) {
		if(type === 'vertical') {
			return depths.split(',').map(v => v.trim());
		}
		
		if(!range) return []

		if(isDual) {
			return range.split(';')[isSecond ? 1 : 0].split(',').map(v => v.trim());
		}

		// if(type === 'circular') {
		// 	return ['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec']
		// }

		if(type === 'vertical') {
			return depths.split(',').map(v => v.trim());
		}

		return range.split(',').map(v => v.trim())

	}

	formatRangeDisplay(range, isDual, isSecond, type, depths) {
		if(type === 'vertical') {
			return depths.split(',').map(v => v.trim());
		}

		if(!range) return []

		if(isDual) {
			return range.split(';')[isSecond ? 1 : 0].split(',');
		}

		// if(type === 'circular') {
		// 	return ['January','February','March','April','May','June','July','August','September','October','November','December'];
		// }

		


		return range.split(',').map(v => v.trim())
	}

	getDefaultGSParam(range, isDual, isSecond, type, depths) {
		
		let list = this.formatRange(range, isDual, isSecond, type, depths);

		if(type === 'vertical') {
			return list[0];
		}

		return list[list.length - 1]
	}	



	formatLayers(data) {
		let layerList = [];

		Object.keys(data)
			.filter(key => key !== 'Description of all codes' && key !== 'Project description')
			.map(key => { 
				layerList = _.concat(layerList, data[key]); return null; 
			});

			return layerList.filter(obj => Object.keys(obj).length > 0)
		.map((obj, key) => {
			return {
				number: key + 1,
				theme_code: obj.layer_theme_new,
				theme_title: obj.layer_theme_new,
				// theme_title: themes[obj.layer_theme],
				isNewVersion: obj.platform === 'v2',
				// name: obj.platform === 'v1' ? (obj.gs_url.indexOf('mosaics:') > -1 ? obj.gs_url.replace(/\./g, 'X').replace(/_/g, '-') : obj.gs_url) : obj.gs_name,
				name: obj.gs_name,
				shortName: obj.layer_filename_shortname,
				pattern: obj.layer_filename_pattern,
				title: obj.layer_title || '',
				description: obj.layer_title_description || '',
				moreInfo: obj.doi || 'https://openlandmap.github.io/book/',
				download: obj.layer_download_url,
				metadata: `https://stac.openlandmap.org/${obj.layer_filename_shortname}/collection.json`,
				units: this.formatLayerUnits(obj.layer_units),
				style: obj.gs_name.indexOf('oem') > -1 ? URL.GEOSERVER_SLD_OEM_BASE + obj.layer_filename_sld : URL.GEOSERVER_SLD_BASE + obj.layer_filename_sld,
				type: obj.layer_slider_type,
				version: obj.layer_filename_pattern?.split('_')[8]?.split('.tif')[0],
				// version: obj.layer_filename_pattern?.split('_')[9]?.split('.tif')[0],
				resolution: obj.layer_filename_pattern?.split('_')[3],
				query: this.formatQueryURL(obj, obj.layer_slider_type),


				range: this.formatRange(obj.range, obj.layer_slider_type === 'both', false, obj.layer_slider_type, obj.depth_list),
				paramGSName: obj.layer_gs_dimension ? obj.layer_gs_dimension.split(';')[0] : null,
				rangeDisplay: this.formatRangeDisplay(obj.range_labels, obj.layer_slider_type === 'both', false, obj.layer_slider_type, obj.depth_list_labels),
				paramDisplayName: this.formatLayerDimensionName(obj.layer_slider_type),
				paramDefaultValue: this.getDefaultGSParam(obj.range, obj.layer_slider_type === 'both', false, obj.layer_slider_type, obj.depth_list),


				range2: this.formatRange(obj.range, obj.layer_slider_type === 'both', true, obj.layer_slider_type, obj.depth_list),
				paramGSName2: obj.layer_gs_dimension ? obj.layer_gs_dimension.split(';')[1] : null,
				rangeDisplay2: this.formatRangeDisplay(obj.range_labels, obj.layer_slider_type === 'both', true,obj.layer_slider_type, obj.depth_list_labels),
				paramDisplayName2: this.formatLayerDimensionName(obj.layer_slider_type, true),
				paramDefaultValue2: this.getDefaultGSParam(obj.range, obj.layer_slider_type === 'both', true, obj.layer_slider_type, obj.depth_list),
				transform_function: obj.transform_function,
			}
		});

	}

	getLayers() {

		
		return axios.get(URL.LAYERS, {cancelToken: layersSource.token})
			.then(result => {

				try {

					let {data} = result;
					let layerList = [];

					Object.keys(data)
						.filter(key => key !== 'Description of all codes' && key !== 'Project description')
						.map(key => { 
							layerList = _.concat(layerList, data[key]); return null; 
						});
					
					this.layers = this.formatLayers(data);
					return this.layers
				} catch(e) {
					this.layers = this.formatLayers(backup);
					return this.layers;
				}				
			})
			.catch(err => {
				this.layers = this.formatLayers(backup);
				return this.layers;
			})
	}

	getLayerShortName(name) {
		return this.layers.filter(obj => obj.shortName === name)[0]?.shortName
	}

	getLayerObject(name) {
		return this.layers?.filter(obj => obj.shortName === name)[0] || {}
	}

	getLayerObjectByFilename(filename) {
		return this.layers?.filter(obj => {
			return filename.match(obj.shortName)
		})[0] || {}
	}

	formatSupportDate(time, name)  {
		let now = moment();

		let date = moment(time);
		let years = now.diff(date, 'years');
		let months = now.diff(date, 'months');
		let weeks = now.diff(date, 'weeks');
		let days = now.diff(date, 'days');
		let hours = now.diff(date, 'hours');
		let minutes = now.diff(date, 'minutes');

		if(years >= 1) {
			return `${years} ${years === 1 ? 'year' : 'years'} ago by ${name}`;
		}

		if(months >= 1 && months < 12) {
			return `${months} ${months === 1 ? 'month' : 'months'} ago by ${name}`;
		}

		if(weeks >= 1 && weeks < 5) {
			return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago by ${name}`;
		}

		if(days >= 1 && days < 31) {
			return `${days} ${days === 1 ? 'day' : 'days'} ago by ${name}`;
		}

		if(hours >= 1 && hours < 24) {
			return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago by ${name}`;
		}

		if(minutes >= 1 && minutes < 60) {
			return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago by ${name}`;
		}

		return `Just now`;
	}

	getSupport() {
		
		return axios.get(URL.GITLAB_ISSUES)
			.then(result => result.data
				// .filter(obj => obj.state === 'opened')
				.map(obj => {
					return {
						title: obj.title,
						description: _.unescape(obj.body),
						timeAndUser: this.formatSupportDate(obj.updated_at, obj.user.login),
						link: obj.html_url
					}
				})
				
			)
			.catch(err => {throw err})
	}

	getYoutubeFeed() {
		return axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://www.youtube.com/feeds/videos.xml?channel_id=UC6HFFFYiV4zEYJlQMIXemWA')
			.then(result => {
				return result.data;
			})
			.catch(err => {throw err})
	}	

	getFeed() {
		return axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@opengeohub')
			.then(result => {
				try {

					let feed = [];
					result.data.items
					.map((obj) => {
						feed.push({
							title: obj.title,

							link: obj.link,
							time: `Published: ${moment(obj.pubDate).format('MMM DD, YYYY')}`,
							description: obj.categories.map(v => `#${v}`).join(' ')
						})
					})
					return feed;
				} catch(e) {
					console.log(e)
					return []
				}
					

			})
			.catch(err => { throw err})

	}

	formatLegend(sldString, units) {
		try {
			let formated = sldString
				.replace(/\<(?=\<\?)(.*?\>)/g, '')
				.replace(/(?<=\<\/)(.*?\:)/g, '')
				.replace(/(?<=\<)(.*?\:)/g, '');

			let sliced = formated.slice(formated.indexOf('<ColorMapEntry'), formated.indexOf('</ColorMap>'));

			let legend = [];
			
			sliced.split('/>').map(val => {

				if(val.trim()) {
					let color = [...`${val} />`.matchAll(/color="([^"]*)"/g)][0][1];

					let label = [...`${val} />`.matchAll(/label="([^"]*)"/g)][0][1];

					legend.push({color,label})
				}
				

				// legend.push({color})
			})

			return legend;
			let parsed = parser.xml2json(formated.slice(formated.indexOf('<ColorMapEntry'), formated.indexOf('</ColorMap>')))
			return parsed.ColorMapEntry.map(obj => ({...obj, units}))

		}
	
		catch(e) {
			console.log(e)
		}
	}


	getLegend(layer) {
		let layerObj = this.getLayerObject(layer);

		if(!layerObj) return [];

		return axios.get(layerObj.style)
			.then(result => {
				
				return this.formatLegend(result.data, layerObj.units).reverse();
			})
			.catch(err => {throw err})
	}

	getLocations(query) {
		if(!query) return;
		return axios.get(URL.NOMINATIM + query)
			.then((result) => {
				return result.data.map(obj => ({
					key: obj.place_id,
					title: obj.display_name,
					description: `${obj.lat}, ${obj.lon}`,
					coordinates: fromLonLat([parseFloat(obj.lon), parseFloat(obj.lat)])
				}))
			})
			.catch(err => {throw err})
	}

	getLocationsReverse(coords) {
		if(!coords) return;
		return axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${coords[1]}&lon=${coords[0]}&format=json`)
			.then((result) => {
				let obj = result.data;

				if(obj.hasOwnProperty('error')) {
					return []
				}
				return [{
					key: obj.place_id,
					title: obj.display_name,
					description: `${obj.lat}, ${obj.lon}`,
					coordinates: fromLonLat([parseFloat(obj.lon), parseFloat(obj.lat)])
				}]
			})
			.catch(err => {throw err})
	}


	getMastodonPosts() {
		return axios.get('https://fosstodon.org/api/v1/accounts/323362/statuses?exclude_replies=true')
			.then(result => {
				return result.data.map(obj => {
					const isBoosted = Boolean(obj.reblog);
					return {
						isBoosted: isBoosted,
						channel: 'https://fosstodon.org/@opengeohub', 
						username: isBoosted ? `@${obj.reblog.account?.username}` : `@${obj.account?.username}`,
						displayName: isBoosted ? obj.reblog.account?.display_name : obj.account?.display_name,
						avatar: isBoosted ? obj.reblog.account.avatar_static : obj.account.avatar_static,
						date: moment(obj.created_at).format('MMM D'),
						content: obj.reblog?.content || obj.content,
						postUrl: obj.reblog?.url || obj.url,
						media: obj.media_attachments.length > 0 ? obj.media_attachments[0]?.preview_url : null
					}
				})
			})
			.catch(err => {throw err})
	}

	getUsageStats() {
		return axios.get(URL.STATS)
			.then(result => result.data)
			.catch(err => {
				throw err
			})
	}

	getViewStats() {
		return axios.get(URL.VIEW_STATS)
			.then(result => result.data)
			.catch(err => {throw err})
	}

	postViewStat(layerObj, time, depth, map) {
		if(map.getView().getZoom() > 8) {
			let results = {
				layer: layerObj.title,
				layerId: layerObj.shortName,
				bbox: map.getView().calculateExtent().join(','),
				time: ''
			};
	
			if(layerObj.type !== 'vertical' && layerObj.type !== 'single') {
				results.time = time.toString();
			}	
		
			return axios.post(URL.VIEW_STATS, results);

		}
	}
}

export default new $data();