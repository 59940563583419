import { Button } from "semantic-ui-react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";
import RangeSlider from 'rc-slider';

function SecondDepth(props) {


  const layerObj = $data.getLayerObject(props.layer);

  let index = layerObj.range2.indexOf(props.depth);

  const getMarkPosition = (i) => {
    if(i === 0) return 0;

    if(i === 1) return 10;

    if(i === 2) return 30;

    if(i === 3) return 60;

    if(i === 4) return 100;

    if(i === 5) return 200;
  }

  let marks = {};

  layerObj.range2.map((v, i) => {
    marks[getMarkPosition(i)] = layerObj.rangeDisplay[i]
  });

  const renderMobile = () => {
    return (
      <div style={{bottom: '50px'}} className="second-depth-slider-component">
        {layerObj.rangeDisplay2.map((name, key) => {
          return <Button key={key} className={key === index ? 'active' : ''} onClick={() => props.onUpdateState({[props.secondary ? 'depth2' : 'depth']: layerObj.range2[key]})}>{name}</Button>
        })}
      </div>
    )
  }

  return (
    <>
    {renderMobile()}
    </>
  )
}

export default SecondDepth;