export default function Badge(props) {
	return (
		<div style={{
			background: '#34495e',
			color: '#fff',
			padding: '3px 5px',
			minWidth: '30px',
			borderRadius: '5px',
			textAlign: 'center'
		}}>{props.content}</div>
	)
}