import { useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";

function SplitScreen(props) {
  const isDown = useRef(false);
  const dividerRef = useRef(null);

  const onMouseDown = (evt) => {
    if(evt.target.className === 'split-screen-divider' || evt.target.className.indexOf('split-screen-icon') > -1 ) {
      isDown.current = true;
    } else {
      isDown.current = false;
    }
  }

  const onMouseMove = (evt) => {
    evt.preventDefault();
    if(isDown.current) {
      let x = evt.clientX;
      dividerRef.current.style.left = x + 'px';

      props.onUpdatePosition(x);
    }
  }

  const onMouseUp = (evt) => {
    isDown.current = false;
  }

  useEffect(() => {
    // if(props.position) {
    //   let position = JSON.stringify(props.position);
    //   console.log(position)
    //   dividerRef.current.style.left = position.indexOf('%') > -1 ? position : position + 'px';
    // }

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  }, [])

  return (
    <div ref={dividerRef} className="split-screen-divider" style={{cursor: 'pointer', zIndex: 0, position: 'absolute', top: 0, left: props.position, transform: 'translateX(-50%)', width: '4px', background: '#34495e', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Icon className="split-screen-icon" size="large" style={{margin: 0, cursor: 'pointer', borderRadius: '100%', background: '#34495e', width: '40px', height: '40px', color: "#fff", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} name="sidebar" />
    </div>
  )
}

export default SplitScreen;