import { useContext, useEffect } from "react"
import { Icon, Loader, Table } from "semantic-ui-react";
import $data from "../../../services/$data";
import { AppContext } from "../../AppContext"
import TabHeader from "./TabHeader";
import useMergeState from '../../hooks/useMergeState';
import oghLogo from '../../../images/opengeohub-logo.png'
import Response from "../../helper/Response";

function OpenGeoHubNewsTab(props) {

	const [state, setState] = useMergeState({data: [], loading: true, error: null})

	useEffect(() => {
		$data.getFeed()
			.then(data => {
				setState({data, loading: false})
			})
			.catch(err => {
				setState({loading: false, error: "Error occured while trying to fetch the news"})
			})
	}, [])

	const renderContent = () => {
		return (
			<Response loading={state.loading} noData="Nothing posted yet" error={state.error} response={state.data}>
			{
			 state.data.map((obj, key) => {
				return <div key={key} style={{padding: '5px'}}>
					<a className="title text-primary" href={obj.link} target="_blank">{obj.title}</a>
					<p className="description" style={{wordWrap: 'break-word'}}>{obj.description === '' ? '' : obj.description.split(' ').filter((obj, key) => key < 30).join(' ') + '...'}</p>
					<p className="time-user">{obj.time}</p>
				</div>
				})
			}
			</Response>
		)
		
	}

	return (
		<div className="olm tabs-container ogh-news-container text-center">
			<TabHeader title="Medium	Publications"></TabHeader>
			<a href={'https://medium.com/@opengeohub'} target="_blank"><img src={oghLogo} width="80%" style={{margin: '10px 0'}} /></a>
			<div className="tab-content text-left" style={{padding: '10px'}}>
				{renderContent()}
			</div>
		</div>
	)
}

export default OpenGeoHubNewsTab