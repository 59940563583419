import _ from "lodash";
import { trackParams, trackParamsNames } from "../config/defaults";
import $data from "./$data";

class $params {
  init(params) {
    let parsed = this._parseParams(params);
    let filtered = this._filterParams(parsed);
    let formated = this._formatParams(filtered, true)
    return this._updateURL(JSON.stringify(formated));
  }

  updateParams(params) {
    let formated = this._formatParams(params, false);
    return this._updateURL(JSON.stringify(formated));
  }

  _getDisplayValues(params = []) {

    let layerObj = $data.getLayerObject(JSON.parse(params).filter(obj => obj.name === 'layer')[0].value)
    return JSON.parse(params).map(obj => {
      if(obj.name === 'time' || obj.name === 'depth') {
        if(layerObj?.type === 'both' && obj.name === 'depth') {
          obj.value = layerObj.rangeDisplay2[layerObj.range2.indexOf(obj.value)]
        } else {
          obj.value = layerObj.rangeDisplay[layerObj.range.indexOf(obj.value)]
        }
        // console.log(layerObj.rangeDisplay[layerObj.range.indexOf(obj.value)])
      }
      return {...obj};
    })
  }

  _updateURL(formated = []) {
    let queryString = this._getDisplayValues(formated).map(obj => `${obj.name}=${obj.value}`).join('&');
    window.history.replaceState('', null,  `${window.location.origin}?${queryString}`);

    let result = {};
    JSON.parse(formated).map(v => ({...v})).map(obj => {
      result[obj.name] = obj.name === 'center' ? obj.value.split(',') : obj.value;
      return null
    })
    return result;

   

  }

  _parseParams(params = '') {
    let withoutQuestionMark = params.split('?')[1];

    if(!withoutQuestionMark) return {}

    let paramArr = withoutQuestionMark.split('&');

    let result = {};

    paramArr.map(keyValue => {
      let separateKeyValue = keyValue.split('=');

      if(separateKeyValue[0]) {
        result[separateKeyValue[0]] = separateKeyValue[1]
      }

      return null
    })

    return result;
  }

  _filterParams(parsedParams = {}) {
    let filtered = {};

    Object.keys(parsedParams).map(name => {
      if(trackParamsNames.indexOf(name) > -1) {
        filtered[name] = parsedParams[name]
      }

      return null
    })

    return filtered

  }

  _formatParams(filteredParams = {}, fromURL) {
    let layerValue = trackParams.filter(obj => obj.name === 'layer')[0].validate(filteredParams.layer);
    // let formated = [{name: 'layer', value: layerValue}];
    let formated = [];

    trackParamsNames.map(name => {
      let paramValidationObj = trackParams.filter(obj => obj.name === name)[0];
      formated.push({ name, value: paramValidationObj.validate(filteredParams[name], layerValue, fromURL) })
    })

    return formated
      .filter(obj => obj.value)
      .sort((a, b) => {
        let objA = trackParams.filter(obj => obj.name === a.name)[0]
        let objB= trackParams.filter(obj => obj.name === b.name)[0]

        return objA.num - objB.num
    })
  }
}

export default new $params();