import { Loader } from 'semantic-ui-react';
import logoOLM from '../images/logo-white2.png';
import oghLogo from '../images/logo-white2.png'

function SplashLoader(props) {
  return (
    <div className={'olm splash-loader-container' + (props.active ? ' active' : '')} style={{position: 'fixed', width: '100%', height:'100%', background: '#34495e', 
       display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {/* <img style={{marginRight: '10px'}} width="50" /> */}
        <img src="/assets/openlandmap_logo_w.png" width="287"  style={{marginRight: '10px'}}/>
      </div>
      <Loader className='olm splash-loader' size='big' active />
    </div>
  )
}

export default SplashLoader;