const host = process.env.REACT_APP_BRANCH === 'dev' ? 'https://v2.openlandmap.org/' : 'https://openlandmap.org/';
// const api = process.env.REACT_APP_BRANCH === 'dev' ? 'https://v2-api.openlandmap.org/query/' : 'https://api.openlandmap.org/query/';
const api = process.env.REACT_APP_BRANCH === 'dev' ? 'https://v2-api.openlandmap.org/' : 'https://api.openlandmap.org/query/';
const geoserver = process.env.REACT_APP_BRANCH === 'dev' ? 'https://v2-geoserver.openlandmap.org/' : 'https://geoserver.openlandmap.org/';
const old_geoserver = 'https://geoserver.openlandmap.org/geoserver/'

console.log(geoserver)
const urls = {
	HOST: host, API: api, 
	GEOSERVER: geoserver,
	OLD_GEOSERVER: old_geoserver,
	LAYERS: api + 'populate',
	QUERY: api + 'query/point',
	GEOSERVER_BASE: geoserver + 'geoserver/',
	GEOSERVER_SLD_BASE: geoserver + 'geoserver/rest/workspaces/olm/styles/',
	GEOSERVER_SLD_OEM_BASE: geoserver + 'geoserver/rest/workspaces/oem/styles/',
	GITLAB_ISSUES: 'https://api.github.com/repos/openlandmap/spatial-layers/issues',
	NOMINATIM: 'https://nominatim.openstreetmap.org/search?format=json&limit=4&q=',
	STATS: api + 'stats/',
	VIEW_STATS: api + 'viewport-stats/'
}

export default urls;