import { useEffect, useState } from "react";
import $data from "../../../services/$data";
import TabHeader from "./TabHeader";
import Response from "../../helper/Response";

function YoutubeTab(props) {
  const [videos, setVideos] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    $data.getYoutubeFeed()
      .then(data => {

        setVideos(data.items);
      })
      .catch(err => {
        console.log(err);
        setError('Unable to load videos');
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])

  return (
    <div className="olm tabs-container gitlab-container youtube-container ">
      <TabHeader title="OpenGeoHub Youtube Channel"></TabHeader>

      <div className="tab-content " style={{ padding: '10px' }}>
        <Response loading={loading} error={error} noData="Nothing posted yet!" response={videos}>
          {
            videos.map((obj, key) => {
              return <div className="splash-loader-container"  key={key} style={{ padding: '5px', marginBottom: '10px' }}>
                <a className="title text-primary" href={obj.link} target="_blank">{obj.title}</a>
                <div style={{marginTop: '5px'}}>
                  <iframe style={{width: '100%', height: '200px', border: 'none', boxShadow: '2px 2px 5px .5px rgba(#000, 0.8)'}} 
                    src={"https://www.youtube.com/embed/" + obj.guid.split(':')[2]}>
                  </iframe>
                </div>
              </div>
            })
          }
        </Response>
      </div>
    </div>
  )
}

export default YoutubeTab;