import olMap from 'ol/Map';
import View from "ol/View";
import { fromLonLat, toLonLat } from "ol/proj";
import { useContext, useEffect, useRef, useState } from "react";
import $map from "../../services/$map";
import { AppContext } from "../AppContext";
import _ from 'lodash';
import $data from '../../services/$data';
import { unByKey } from 'ol/Observable';
import OLCesium from 'olcs/OLCesium';
import $query from '../../services/$query';
import Overlay from 'ol/Overlay';
import InfoWindow from './InfoWIndow';
import { Icon } from 'semantic-ui-react';
import useMergeState from '../hooks/useMergeState';
import toast from 'react-hot-toast';
import SplitScreen from './SplitScreen';
import { getRenderPixel } from 'ol/render';
import FeatureWindow from './FeatureWindow';

function Map(props) {
	const mapElement = useRef(null);
	const context = useContext(AppContext);
	const { base, layer, layer2, center, zoom, opacity, time, depth, time2, depth2, cesium, geolocation, comparison, onUpdateState } = context;
	const queryObj = useRef({ layer, cesium });
	const infoRef = useRef(null);
	const positionRef = useRef(window.innerWith / 2);
	const [position, setPosition] = useState(window.innerWidth / 2);
	const [queryState, setQueryState] = useMergeState({ loading: false, data: [], coordinates: null, features: null })

	useEffect(() => {
		let basemaps = $map.generateBasemaps(base);


		$map.info = new Overlay({
			element: infoRef.current,
			positioning: 'bottom-center',
			offset: [0, -20],
			autoPan: true,
		})

		let pinElement = document.createElement('div');
		pinElement.className = 'pin-element'

		let geoElement = document.createElement('div');
		geoElement.className = 'geo-element'

		$map.pin = new Overlay({
			element: pinElement,
			positioning: 'center',
			stopEvent: false
		});

		$map.geoPin = new Overlay({
			element: geoElement,
			positioning: 'center',
			stopEvent: false
		});

		$map.instance = new olMap({
			target: mapElement.current,
			layers: basemaps,
			overlays: [$map.info, $map.pin, $map.geoPin],
			maxTilesLoading: 32,
			view: new View({
				center: fromLonLat(center),
				zoom
			})
		});

		let layers = $map.generateLayers(layer, opacity, time, depth);


		let layers2 = $map.generateLayers(layer2, opacity, time2, depth2, true);
		layers.map(l => $map.instance.addLayer(l))
		layers2.map(l => $map.instance.addLayer(l))


		let moveEnd = $map.instance.on('moveend', (evt) => {
			if (JSON.stringify(center) !== JSON.stringify(evt.map.getView().getCenter())) {

				$map.pin.setPosition(null)
			}
			onUpdateState({
				zoom: evt.map.getView().getZoom(),
				center: toLonLat(evt.map.getView().getCenter())
			});


		})

		let pointerMove = $map.instance.on('pointermove', (evt) => {
			if (evt.dragging) return;

			if (evt.map.hasFeatureAtPixel(evt.pixel)) { mapElement.current.style.cursor = 'pointer'; }
			else { mapElement.current.style.cursor = 'initial'; }
		})

		let queryListener = $map.instance.on('singleclick', (evt) => { queryPreAction(evt, cesium, layer) })

		$map.instance3D = new OLCesium({ map: $map.instance });

		$map.instance3D.getCesiumScene().terrainProvider = window.Cesium.createWorldTerrain({ requestVertexNormals: true });

		return () => {
			unByKey(moveEnd);
			unByKey(pointerMove);
			unByKey(queryListener);
			$map.cancelAllWMSRequests();
			$map.instance.setTarget(null);
			$map.instance = null;
		}
	}, []);

	useEffect(() => {
		if ($map.instance) {
			$map.setBase(base)
		}
	}, [base])

	useEffect(() => {
		if ($map.instance) {
			let layerObj = $data.getLayerObject(layer);
			$data.postViewStat(layerObj, time, depth, $map.instance);

		}
	}, [layer, time, zoom, center])

	useEffect(() => {
		queryObj.current = ({ ...queryObj.current, layer });
		if ($map.instance) {
			let layerObj = $data.getLayerObject(layer);

			$data.postViewStat(layerObj, time, depth, $map.instance);
			if (layerObj) {
				if (layerObj.type !== 'SINGLE') {
					$map.setLayer(layer, context[layerObj.paramDisplayName], { time, depth });
				} else {
					$map.setLayer(layer);
				}
				$map.toggle3DPoints(cesium, layer)
			}

			if (queryState.coordinates) {
				queryPreAction({ pixel: $map.instance.getPixelFromCoordinate(fromLonLat(queryState.coordinates)), map: $map.instance, coordinate: fromLonLat(queryState.coordinates) })
			}
		}
	}, [layer])

	useEffect(() => {
		if ($map.instance) {
			let layerObj = $data.getLayerObject(layer2);

			if (layerObj) {
				if (layerObj.type !== 'SINGLE') {
					$map.setLayer2(layer2, context[layerObj.paramDisplayName], comparison, { time: time2, depth: depth2 });
				} else {
					$map.setLayer2(layer2, null, comparison);
				}
				$map.toggle3DPoints2(cesium, layer2)
			}
		}
	}, [layer2])

	useEffect(() => {
		if ($map.instance) {
			$map.setOpacity(opacity)
		}
	}, [opacity]);

	useEffect(() => {
		if ($map.instance) {
			let layerObj = $data.getLayerObject(layer);
			
			if (layerObj.type !== 'single' && layerObj.type !== 'both') {
				$map.updateDimension(context[layerObj.paramDisplayName], layer);
			}

			if (layerObj.type === 'both') {
				$map.updateDualDimension(time, depth, layer)
			}
		}
	}, [time, depth])


	useEffect(() => {
		if ($map.instance) {
			let layerObj = $data.getLayerObject(layer2);
			if (layerObj.type !== 'single' && layerObj.type !== 'both') {
				$map.updateDimension2(context[layerObj.paramDisplayName + '2'], layer2);
			}

			if (layerObj.type === 'both') {
				$map.updateDualDimension2(time2, depth2, layer2)
			}
		}
	}, [time2, depth2])

	useEffect(() => {
		queryObj.current = ({ ...queryObj.current, cesium });

		if ($map.instance) {
			$map.toggle3DPoints(cesium, layer)
			$map.instance3D.setEnabled(cesium)
			if (!cesium) {
				$map.instance.getView().setRotation(0)
			}
		}
	}, [cesium]);

	useEffect(() => {
		if ($map.instance) {
			if (geolocation) {
				if (window.navigator) {
					let latLon = window.navigator.geolocation.getCurrentPosition((evt) => {
						try {
							let transformed = fromLonLat([evt.coords.longitude, evt.coords.latitude]);
							$map.zoomToPoint(transformed, true)
						} catch (e) {
							toast.error('Unable to access your location!')
						}

					}, (err) => {
						toast.error('Unable to access your location!')
					});

				}
			} else {
				$map.geoPin.setPosition(null)
			}
		}
	}, [geolocation]);

	const onPrerender = (evt) => {
		const ctx = evt.context;
		const mapSize = $map.instance.getSize();
		const width = positionRef.current;
		let tl = getRenderPixel(evt, [0, 0]);
		let tr = getRenderPixel(evt, [width, 0]);
		let bl = getRenderPixel(evt, [0, mapSize[1]]);
		let br = getRenderPixel(evt, [width, mapSize[1]]);

		ctx.save();
		ctx.beginPath();
		ctx.moveTo(tl[0], tl[1]);
		ctx.lineTo(bl[0], bl[1]);
		ctx.lineTo(br[0], br[1]);
		ctx.lineTo(tr[0], tr[1]);
		ctx.closePath();
		ctx.clip();
	}
	const onPrerender2 = (evt) => {
		const ctx = evt.context;
		const mapSize = $map.instance.getSize();
		const width = positionRef.current;
		let tl = getRenderPixel(evt, [width, 0]);
		let tr = getRenderPixel(evt, [mapSize[0], 0]);
		let bl = getRenderPixel(evt, [width, mapSize[1]]);
		let br = getRenderPixel(evt, mapSize);

		ctx.save();
		ctx.beginPath();
		ctx.moveTo(tl[0], tl[1]);
		ctx.lineTo(bl[0], bl[1]);
		ctx.lineTo(br[0], br[1]);
		ctx.lineTo(tr[0], tr[1]);
		ctx.closePath();
		ctx.clip();
	}

	const onPostrender = (evt) => {
		const ctx = evt.context;
		ctx.restore();
	}
	const onPostrender2 = (evt) => {
		const ctx = evt.context;
		ctx.restore();
	}

	useEffect(() => {
		if ($map.instance) {
			if (comparison) {


				$map.layers.map(l => {
					if (l.get('isLayerGroup')) {
						l.getLayers().getArray().map(gl => {
							$map.keys.push(gl.on('prerender', onPrerender));
							$map.keys.push(gl.on('postrender', onPostrender));
						})
					} else {
						$map.keys.push(l.on('prerender', onPrerender));
						$map.keys.push(l.on('postrender', onPostrender));
					}

				})

				$map.layers2.map(l => {
					if (l.get('isLayerGroup')) {
						l.getLayers().getArray().map(gl => {
							$map.keys.push(gl.on('prerender', onPrerender2));
							$map.keys.push(gl.on('postrender', onPostrender2));
						})
					} else {
						$map.keys.push(l.on('prerender', onPrerender2));
						$map.keys.push(l.on('postrender', onPostrender2));
					}

				});

				let layerObj = $data.getLayerObject(layer2);

				if (layerObj) {
					if (layerObj.type !== 'SINGLE') {
						$map.setLayer2(layer2, context[layerObj.paramDisplayName + '2'], comparison, { time: time2, depth: depth2 });
					} else {
						$map.setLayer2(layer2, null, comparison);
					}
					$map.toggle3DPoints2(cesium, layer2)
				}

				$map.instance.render();

			} else {
				$map.keys.map(key => {
					unByKey(key);
				});
				$map.hideComparisonLayers()
				$map.keys = [];

				$map.instance.render();
			}

		}
	}, [comparison]);

	useEffect(() => {
		if ($map.instance) {
			positionRef.current = position;
			$map.instance.render();

			let group = $map.layers.filter(l => l.get('isLayerGroup') && l.get('name') === layer)[0];
			let group2 = $map.layers2.filter(l => l.get('isLayerGroup') && l.get('name') === layer2)[0];

			if (group) {
				group.getLayers().getArray()[2].getSource().refresh();
			}

			if (group2) {
				group2.getLayers().getArray()[2].getSource().refresh();
			}

		}
	}, [position])

	const queryPreAction = (evt) => {

		setQueryState({ loading: true, error: false, coordinates: toLonLat(evt.coordinate), features: null })


		let group = $map.isGFIQuery(queryObj.current.layer, queryObj.current.cesium);

		if (group) {
			let url = group.getSource().getFeatureInfoUrl(evt.coordinate, evt.map.getView().getResolution(), 'EPSG:3857', { 'info_format': 'application/json', 'feature_count': 100 });
			$query.getFeatureInfo(url)
				.then(features => {
					if (!features) {

						queryAction(evt.coordinate);
					} else {
						//SHOW INFO WINDOW
						$map.info.setPosition(null);
						setQueryState({ loading: false, features })
					}
				})
				.catch(err => {
					queryAction(evt.coordinate);
				})
			return
		}

		let features = $map.instance.getFeaturesAtPixel(evt.pixel);

		if (features[0]) {
			$map.info.setPosition(null);

			setQueryState({ loading: false, features })

			return
		}

		queryAction(evt.coordinate);


	}

	const queryAction = (coordinates) => {
		$map.info.setPosition(coordinates);
		let layerObj = $data.getLayerObject(queryObj.current.layer);
		let latLon = toLonLat(coordinates);
		let lat = latLon[1];
		let lon = latLon[0];

		if (layerObj) {
			$query.get(layerObj.query.replace('__LAT__', lat).replace('__LON__', lon), layerObj, layerObj.transform_function)
				.then(data => {
					console.table(data)
					setQueryState({ loading: false, data, features: null })

				})
				.catch(err => {
					$query.get(layerObj.query.replace('__LAT__', lat).replace('__LON__', lon), layerObj, layerObj.transform_function)
						.then(data => {
							console.table(data)
							setQueryState({ loading: false, data, features: null })

						})
						.catch(err => {
							$query.get(layerObj.query.replace('__LAT__', lat).replace('__LON__', lon), layerObj, layerObj.transform_function)
								.then(data => {
									console.table(data)
									setQueryState({ loading: false, data, features: null })

								})
								.catch(err => { console.log(err); setQueryState({ loading: false, data: [], error: true, features: null }) })
						})
				})
		}
	}


	return (
		<div style={{ width: '100%', height: '100%' }} ref={mapElement}>
			<div ref={infoRef} className='info-window-container'>
				<Icon onClick={() => { setQueryState({ data: [], loading: false, error: false, coordinates: null }); $map.info.setPosition(null) }} className='close-info' name="times circle" />
				<InfoWindow layerObj={$data.getLayerObject(layer)} queryState={queryState} />
				<FeatureWindow features={queryState.features} onClose={() => setQueryState({ features: null })} />
			</div>

			{comparison && <SplitScreen position={position} onUpdatePosition={(x) => { setPosition(x); positionRef.current = x; }} />}

		</div>
	)
}

export default Map;