import { useContext, useEffect, useState } from "react";
import { Accordion, Checkbox, Icon, Input, Radio } from "semantic-ui-react";
import $data from "../../../services/$data";
import { AppContext } from "../../AppContext";
import TabHeader from "./TabHeader";
import { Range, getTrackBackground } from "react-range";
import mercator from '../../../images/mercator-adjusted.jpg';

function LayersTab(props) {
  const {layer, opacity, legend, activeIndex, onUpdateState} = useContext(AppContext);

  const [filter, setFilter] = useState("");

  useEffect(() => {
    let newIndex = parseInt($data.getLayerObject(layer)?.number[0]) - 1;
    if(newIndex !== activeIndex) {
      onUpdateState({activeIndex: newIndex});
    }
  }, [])
  
  useEffect(() => {
    let item = document.querySelector('.layers-tab-item.active');
    if(item) {
      setTimeout(() => {
        item.scrollIntoView({block: 'start'})

      })
    }
  }, [activeIndex])

  useEffect(() => {

    let groups = generateGroups() || {};
    let activeGroup = null;

    Object.keys(groups).map((g, index) => {
      groups[g].map(l => {
        if(l.shortName === layer) {
          activeGroup = index;
        }
      })
    });

    if(activeGroup) {
      onUpdateState({activeIndex: activeGroup})
    }



  }, [layer])

  const generateGroups = () => {
    let groups = {};

    $data.layers
    .filter(obj => {
      if(filter === '') return true;

      let title = obj.title.trim().toLowerCase().split(' ').join('');
      let theme_code = obj.theme_code.trim().toLowerCase().split(' ').join('');
      let theme_title = obj.theme_title.trim().toLowerCase().split(' ').join('');
      let tFilter = filter.trim().toLowerCase().split(' ').join('');
      if(title.indexOf(tFilter) > -1 || theme_code.indexOf(tFilter) > -1 || theme_title.indexOf(tFilter) > -1) return true;

      return false
    })
    .map(obj => {
      if(groups[obj.theme_title]) {
        groups[obj.theme_title].push(obj);
      } else {
        groups[obj.theme_title] = [obj]
      }

      return null
    })

    return groups
  }

  const renderGroups = () => {
    let groups = generateGroups() || {};

    return Object.keys(groups).map((theme, i) => {
      return [
        <Accordion.Title key={i + 1} active={activeIndex === i} className={(activeIndex === i ? ' active' : '')} index={i} onClick={(e, {index}) => {onUpdateState({activeIndex: activeIndex === index ? -1 : index})}}>
          {theme}
        </Accordion.Title>,
        <Accordion.Content key={(i + 1) * (-1)} active={activeIndex === i}>
          {renderLayers(groups[theme])}
        </Accordion.Content>
      ]
    })
  }

  const renderLayers = (groupList = []) => {
    return groupList.map((obj, key) => {
      return (
        <div key={key} className={"layers-tab-item" + (layer === obj.shortName ? ' active' : '')}>
          <div>
            <Radio label="" checked={obj.shortName === layer} onChange={(evt, {checked}) => {onUpdateState(Object.assign({animation: false, layer: obj.shortName, [obj.paramDisplayName]: obj.paramDefaultValue }, obj.type === 'both' ? {depth: '_s_'} : {})); console.log(obj)}}/>
          </div>
          <div className="layers-tab-item-details">
            <div onClick={(evt) => onUpdateState({layer: obj.shortName})} className="layer-tab-item-title"><strong>{obj.title}</strong></div>
            <div className="layer-tab-item-description">{obj.description}</div>
            <div className="layers-tab-item-links">
              <a href={obj.moreInfo} target="_blank"><Icon name="hand point right" /> Read more</a>
              {/* <a href={obj.download} target="_blank"><Icon name="download" /> Download</a> */}
              <a href={obj.metadata || ''} target="_blank"><Icon name="database" /> Metadata & Download</a>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className="tabs-container layers-container">
      <TabHeader title="Themes and datasets"></TabHeader>
      
      <div className="map-settings">
        <Input className="input-check-focus" value={filter} onChange={(evt, {value}) => setFilter(value)} icon='search' placeholder='Search datasets...' style={{width: '100%'}} />

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0'}}>
          <span style={{marginRight: '20px'}}>Opacity</span>
          <Range
            values={[parseInt(opacity)]} step={1} min={0} max={100} onChange={(values) => onUpdateState({ opacity: values.toString() })}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{ ...props.style, height: "36px", display: "flex", width: "100%" }} >
                <div ref={props.ref} style={{ height: "5px", width: "100%", borderRadius: "4px", background: getTrackBackground({ values: [parseInt(opacity)], colors: ["#34495e", "#ccc"], min: 0, max: 100 }), alignSelf: "center" }}>
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div {...props} style={{ ...props.style, height: "20px", width: "20px", borderRadius: "100%", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }} ></div>
            )} />  
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '10px 0'}}>
          <span style={{marginRight: '20px'}}>Legend</span>
          <Checkbox toggle checked={legend} onChange={(evt, {checked}) => onUpdateState({legend: checked})} />
        </div>        
      </div>

      <div className="tab-content">
        <Accordion>
          {renderGroups()}
        </Accordion>

        
        <div style={{padding: '10px', textAlign: 'center'}}>
           <hr />
          <a href="https://openlandmap.github.io/book/" target="_blank">Read more about themes and layers available</a> 
          <p> Note: World Mercator projection distort areas</p>
          <a href="https://en.wikipedia.org/wiki/Mercator_projection" target="_blank"><img src={mercator} width="250"/></a>
        </div>
      </div>
     

    </div>
  )
}

export default LayersTab;