import { useContext, useEffect } from "react"
import { Icon, Loader, Table } from "semantic-ui-react";
import $data from "../../../services/$data";
import { AppContext } from "../../AppContext"
import TabHeader from "./TabHeader";
import useMergeState from '../../hooks/useMergeState';
import Response from "../../helper/Response";
import ghlogo from '../../../images/GitHub-Logo.png'

function GitlabTab(props) {

	const [state, setState] = useMergeState({data: [], loading: true})

	useEffect(() => {
		$data.getSupport()
			.then(data => {
				setState({data, loading: false})
			})
			.catch(err => {
				setState({loading: false, error: "Error occured while trying to fetch Gitlab issues!"})
			})
	}, [])

	const renderContent = () => {
		return (
			<Response loading={state.loading} error={state.error} noData="Nothing posted yet!" response={state.data}>
				{
					state.data.map((obj, key) => {
						return <div key={key} style={{padding: '5px'}}>
							<a className="title text-primary" href={obj.link} target="_blank">{obj.title}</a>
							<p className="description" style={{wordWrap: 'break-word'}}>{obj.description.split(' ').filter((obj, key) => key < 30).join(' ') + '...'}</p>
							<p className="time-user">{obj.timeAndUser}</p>
						</div>})
				}			
			</Response>
		)
	}

	return (
		<div className="olm tabs-container gitlab-container text-center">
			<TabHeader title="Support"></TabHeader>
			<a href="https://github.com/openlandmap/spatial-layers/" target="_blank"><img src={ghlogo} width="70%" style={{margin: '10px 0'}} /></a>
			<div className="tab-content text-left" style={{padding: '10px'}}>
				{renderContent()}
			</div>
		</div>
	)
}

export default GitlabTab