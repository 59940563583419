const home = 'https://opengeohub.org/'

const links = {
	home: home,
	aboutOGH: home + 'about/',
	aboutOLM: home + 'about-openlandmap/',
	copyright: home + 'about-landgis/',
	privacyPolicy: home + 'privacy-policy',
	gitlab: 'https://gitlab.com/openlandmap',
	gilab: 'https://gilab.rs/',
	envirometrix: 'https://envirometrix.net',
	geoserver: 'http://geoserver.org/',
	rPlumber: 'https://rplumber.io/',
	react: 'https://reactjs.org/',
	openlayers: 'https://openlayers.org/',
	ranger: 'https://github.com/imbs-hl/ranger',
	xgboost: 'https://github.com/dmlc/xgboost',
	caret: 'https://topepo.github.io/caret/index.html',
	superlearner: 'https://cran.r-project.org/web/packages/SuperLearner/',
	gdal: 'https://www.gdal.org',
	saga: 'https://saga-gis.org',
	grass: 'https://grass.osgeo.org',
	qgis: 'https://qgis.org',
	whitebox: 'https://www.uoguelph.ca/~hydrogeo/WhiteboxTools/index.html',
	landpotential: 'https://landpotential.org/',
	geowiki: 'https://www.geo-wiki.org/',
	spatialEcology: 'https://spatial-ecology.net/',
	margosa: 'https://margosa-env.com/',
	usgsGlobal: 'https://rmgsc.cr.usgs.gov/ecosystems/global.shtml',
	cciLandCover: 'https://www.esa-landcover-cci.org/',
	hyde: 'https://themasites.pbl.nl/tridion/en/themasites/hyde/',
	forestLandscapes: 'https://www.intactforests.org/',
	earthquakes: 'https://earthquake.usgs.gov/earthquakes/',
	modis: 'https://modis-land.gsfc.nasa.gov/',
	globalPrecipitation: 'https://pmm.nasa.gov/',
	globalLandscape: 'https://sil.uc.edu/cms/index.php?id=data-1',
	opentopomap: 'https://opentopomap.org/',
	openstreetmap: 'https://www.openstreetmap.org/',
	bing: 'https://www.bing.com/maps/aerial',
	stamen: 'http://maps.stamen.com/',
	towardsdatascience: 'https://medium.com/@opengeohub'
}

export default links;