import { useCallback, useContext, useState } from "react";
import { Icon, Label, Modal, TransitionablePortal } from "semantic-ui-react";
import ImageViewer from "react-simple-image-viewer";

import { AppContext } from "./AppContext";

import oghLogo from '../images/opengeohub-logo.png'
import links from "../config/links";
import eu from '../images/eu.png'
function About(props) {
	const {about, onUpdateState} = useContext(AppContext);

	const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
	const images = [
    "/assets/oemc.jpg",
    "/assets/pasture.jpg",
  ];

	const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

	const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

	return (
		<>
		<TransitionablePortal open={about} transition={{animation: 'fade up', duration: 500}}>
		<Modal open={about} onClose={() => onUpdateState({about: false})}>
	
			<Modal.Header style={{borderRadius: 0, color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="background-primary">About <Icon onClick={() => onUpdateState({about: false})} name="times" style={{color: '#fff', cursor: 'pointer'}} /></Modal.Header>
			
			<Modal.Content>
				
				<div style={{display: "flex", justifyContent: "center", alignItems: 'center', flexWrap: 'wrap'}}>
					<a href={links.aboutOGH} target="_blank"><img src={oghLogo} height="130" />	</a>
					<a href={links.gilab} target="_blank"><img src="/assets/gilab.svg" height="130" />	</a>
				</div>

				<div style={{textAlign: 'center', marginBottom: '10px'}}>
					<Label size="big" className="background-primary text-white" horizontal>
						Version 2.0.0
					</Label>
				</div>

				<div style={{marginBottom: '20px'}}>
					<p><a href={links.aboutOLM} target="_blank">OpenLandMap</a> represents data (global land mass), services and web-apps providing access and interactive visualizations of the Terabytes of high resolution data (1 km, 250 m or better) produced by the OpenGeoHub Foundation and/or contributing organizations. OpenGeoHub is a not-for-profit research foundation with headquarters in Wageningen, the Netherlands (Stichting OpenGeoHub, KvK 71844570). The main goal of the <a href={links.aboutOGH} target="_blank">OpenGeoHub</a> is to promote publishing and sharing of Open Geographical and Geoscientific Data and using and developing of Open Source Software.</p>
				</div>

				<div style={{marginBottom: '20px'}}>
					<a href={links.towardsdatascience} target="_blank"><h3 className="text-center">Everybody has a right to know what's happening with the planet</h3></a>	
				</div>

				<div style={{marginBottom: '20px'}}>
					<p><strong>Open Data</strong></p>
					<p><a href={links.aboutOLM} target="_blank">OpenLandMap</a> is Open Land Data project, mainly inspired by the OpenStreetMap project: you are free to use OpenLandMap data for any purpose as long as you credit OpenLandMap and its contributors. If you alter or build upon the data in certain ways, you may distribute the result only under the same licence. See the <a href={links.copyright} target="_blank">Copyright and License page for details</a>.</p>
				</div>

				<div style={{marginBottom: '20px'}}>
					<p><strong>Legal</strong></p>
					<p>This site and many other related services are formally operated by the <a href={links.aboutOGH} target="_blank">OpenGeoHub Foundation</a> on behalf of the community. Use of all OpenGeoHub Foundation operated services is subject to our <a href={links.aboutOGH} target="_blank">Terms & Conditions</a> and our <a href={links.privacyPolicy} target="_blank">Privacy Policy</a>.</p>
					<p>Please contact the <a href={links.aboutOGH} target="_blank">OpenGeoHub Foundation</a> if you have licensing, copyright or other legal questions.</p>
					<p><a href={links.aboutOLM} target="_blank">OpenLandMap</a> and <a href={links.aboutOGH} target="_blank">OpenGeoHub</a> are registered trademarks of the <a href={links.aboutOGH} target="_blank">OpenGeoHub Foundation</a>.</p>
				</div>

				<div style={{marginBottom: '40px'}}>
					<p><strong>Partners</strong></p>
					<p>Hosting is supported by <a href={links.envirometrix} target="_blank">Envirometrix Ltd</a>, <a href={links.gilab} target="_blank">GILab</a> and other partners.</p>
				</div>

				<div id="web-traffic" style={{width: '250px', margin: '0 auto 40px auto', paddingBottom: '20px'}}><div id="revolvermap" align="center" className="wcustomhtml"><div className="ab"><div className="abc"><iframe className="adc" scrolling="no" frameBorder="0" allowtransparency="true" width="100%" height="100%" src="//rf.revolvermaps.com/w/7/a/a2.php?i=5parbm03zca&amp;m=0&amp;c=ff0000&amp;cr1=ffffff&amp;sx=0"></iframe></div></div></div></div>

				<div style={{marginBottom: '20px'}}>
				<p><strong>Funding sources</strong></p>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', margin: '0 auto 40px auto', width: '100%'}}>
					<img onClick={() => openImageViewer(0)} style={{ cursor: 'pointer', marginBottom: '10px'}} src="/assets/oemc.jpg" />
					<img onClick={() => openImageViewer(1)} style={{ cursor: 'pointer'}} src="/assets/pasture.jpg" />
				</div>

				<div style={{marginBottom: '40px'}}>
					<p><strong>Disclaimer</strong></p>
					<p>Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or European Commision. Neither the European Union nor the granting authority can be held responsible for them. The data is provided “as is”. Open-Earth-Monitor Cyberinfrastructure (OEMC) project consortium and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither OEMC project Consortium nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk.</p>
				</div>

				<div style={{display: 'flex', alignItems: 'start'}}>
					<div style={{marginRight: '10px'}}><img src={eu} width="175" /></div>
					<div style={{}}>
						<p><strong><a href="https://earthmonitor.org" target="_blank">Open Earth Monitor</a></strong></p>
						Open Earth Monitor has received funding from the European Union's Horizon Europe research and innovation programme under grant agreement <a href="https://cordis.europa.eu/project/id/101059548" target="_blank">No. 101059548</a>.

					</div>
				</div>


		
			
			
			</Modal.Content>
		</Modal>
		</TransitionablePortal>
		{isViewerOpen && (
        <ImageViewer

          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
						zIndex: 900000000
          }}
          closeOnClickOutside={true}
        />
      )}
		</>
	)
}

export default About;