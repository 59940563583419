import { useContext } from "react";
import {Icon, Popup} from "semantic-ui-react";
import {AppContext} from "../AppContext";
import MastodonIcon from "../sidebar/tabs/MastodonIcon";

function Toolbar(props) {
  const {activeTab, sidebar, onUpdateState} = useContext(AppContext)

  return (
    <>
      {!props.mobile && <div onClick={() => onUpdateState({sidebar: true})} className="olm toolbar-icon toolbar-icon-mobile">
        <Icon name="bars" />
      </div>}
      <div className={"olm toolbar-container" + (sidebar ? '' : ' sidebar-closed') + (props.mobile ? ' force' : '')}>
        <div className="toolbar-group">
          <Popup position="left center" content="Themes and datasets" trigger={<div onClick={() => onUpdateState({activeTab: 0, sidebar: activeTab === 0 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 0 && sidebar ? ' active' : '')}>
            <Icon name="map" />
          </div>}/>
          <Popup position="left center" content="Layer details" trigger={<div onClick={() => onUpdateState({activeTab: 1, sidebar: activeTab === 1 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 1 && sidebar ? ' active' : '')}>
            <Icon name="hand point right outline" />
          </div>} />
          <Popup position="left center" content="Submit an issue" trigger={<div onClick={() => onUpdateState({activeTab: 2, sidebar: activeTab === 2 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 2 && sidebar? ' active' : '')}>
            <Icon name="github" />
          </div>} />
          <Popup position="left center" content="Medium publications" trigger={<div onClick={() => onUpdateState({activeTab: 3, sidebar: activeTab === 3 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 3 && sidebar? ' active' : '')}>
            <Icon name="medium" />
          </div>} />
          <Popup position="left center" content="Mastodon posts" trigger={<div onClick={() => onUpdateState({activeTab: 4, sidebar: activeTab === 4 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 4 && sidebar? ' active' : '')}>
            <MastodonIcon />
          </div>} />
          <Popup position="left center" content="Youtube channel" trigger={<div onClick={() => onUpdateState({activeTab: 7, sidebar: activeTab === 7 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 7 && sidebar? ' active' : '')}>
            <Icon name="youtube" />
          </div>} />
          <Popup position="left center" content="About OpenLandMap" trigger={<a href="https://openlandmap.github.io/book/" target="_blank"><div onClick={() => {}} className={"toolbar-icon"}>
            <Icon name="book" />
          </div></a>} />
        </div>
        <div className="toolbar-group">
          <Popup position="left center" content="Usage statistics" trigger={<div onClick={() => onUpdateState({stats: true})} className="toolbar-icon">
            <Icon name="chart pie" />
          </div>} />
          <Popup position="left center" content="Hotkeys & Help" trigger={<div onClick={() => onUpdateState({help: true})} className="toolbar-icon">
            <Icon name="question" />
          </div>} />
          <Popup position="left center" content="Credits" trigger={<div onClick={() => onUpdateState({activeTab: 5, sidebar: activeTab === 5 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 5 && sidebar? ' active' : '')}>
            <Icon name="info circle" />
          </div>} />
          {/* <Popup position="left center" content="Themes and datasets" trigger={<div onClick={() => onUpdateState({activeTab: 6, sidebar: activeTab === 6 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 6 && sidebar? ' active' : '')}>
            <Icon name="user circle" />
          </div>} /> */}
          <Popup position="left center" content="Share" trigger={<div onClick={() => onUpdateState({activeTab: 6, sidebar: activeTab === 6 && sidebar ? false : true})} className={"toolbar-icon" + (activeTab === 6 && sidebar? ' active' : '')}>
            <Icon name="share alternate" />
          </div>} />
          <Popup position="left center" content="STAC (access catalogue)" trigger={<a href="https://stac.openlandmap.org" target="_blank"><div onClick={() => {}} className={"toolbar-icon"}>
            <Icon name="database" />
          </div></a>} />
        </div>
      </div>
    </>
  )
}

export default Toolbar;