import { useContext, useEffect, useState } from "react"
import { Icon, Loader, Table } from "semantic-ui-react";
import $data from "../../../services/$data";
import { AppContext } from "../../AppContext"
import TabHeader from "./TabHeader";
import useMergeState from '../../hooks/useMergeState';
import Response from "../../helper/Response";
import { Timeline } from "react-twitter-widgets";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
function TwitterTab(props) {

	const [state, setState] = useMergeState({visible: false, error: false})
	const [loading, setLoading] = useState(true);
	const [posts, setPosts] = useState([]);


	useEffect(() => {
		setState({visible: false, error: false})

		$data.getMastodonPosts()
			.then(data => setPosts(data))
			.catch(err => {setState({error: true})})
			.finally(() => setLoading(false))
	}, [])

	const renderContent = () => {
		if(loading) {
			return <Loader active />
		}

		if(state.error) {
			return (
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '80%'}} className="text-center">
					<p className="text-center text-danger">
						Couldn't load the posts!
					</p>
					<Icon className="twitter-refresh-icon" style={{cursor: 'pointer'}} color="grey" onClick={() => setState({visible: false, error: false})} name="refresh" size="big" />
				</div>
			)
		}

		if(posts.length === 0) {
			return (
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '80%'}} className="text-center">
					<p className="text-center text-primary">
						Nothing posted yet!
					</p>
					<Icon className="twitter-refresh-icon" style={{cursor: 'pointer'}} color="grey" onClick={() => setState({visible: false, error: false})} name="refresh" size="big" />
				</div>
			)
		}

		return posts.map((obj, key) => {
			return renderPost(obj, key);
		})
	} 
	const renderLoader = () => {
		if(state.error) {
			return (
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '80%'}} className="text-center">
					<p className="text-center text-danger">
						Couldn't load the posts!
					</p>
					<Icon className="twitter-refresh-icon" style={{cursor: 'pointer'}} color="grey" onClick={() => setState({visible: false, error: false})} name="refresh" size="big" />
				</div>
			)
		}

		if(state.visible === false) {
			return <Loader active />
		};

	

		return null;
	}

	const renderPost = (post, key) => {
		return (
			<div className="mastodon-post" style={{padding: '10px 10px 15px 10px', textAlign: 'left'}} key={key}>
					{post.isBoosted && <div style={{color: '#777', paddingBottom: '3px', display: 'flex', alignItems: 'start'}}><Icon name="refresh"  /><span>OpenGeoHub boosted</span></div>}
				<a  className="mastodon-post-link" style={{display: 'block', color: '#34495e'}} href={post.postUrl} target="_blank">
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start'}}>
					
					<div style={{display: 'flex', alignItems: 'center'}}>
						<img style={{marginRight: '7px'}} width="46" src={post.avatar} />
						<div style={{textAlign: 'left'}}>
							<div><strong>{post.displayName}</strong></div>
							<div>{post.username}</div>
						</div>
					</div>
					<div style={{marginTop: '2px'}}>{post.date}</div>
				</div>
				</a>

				<div dangerouslySetInnerHTML={{__html: post.content}}></div>
				<img style={{width: '100%'}} src={post.media} />
			</div>
		)
	}

	return (
		<div className="olm tabs-container gitlab-container youtube-container ">
			<TabHeader title="OpenGeoHub Fosstodon"></TabHeader>
			{/* {renderLoader()}
			{!state.error && <div className="tab-content" style={{visibility: state.visible ? 'visible' : 'hidden'}}>
				<TwitterTimelineEmbed
					sourceType="profile"
					screenName="opengeohub"
					transparent={true}
					noBorders
					noFooter
					noHeader
					onLoad={(iframe) => {
						if(!iframe) {
							setState({visible: false, error: true});
						}	else{ 
							setState({visible: true})
						}
					}}/>
			</div>} */}
			<div className="tab-content">
				{renderContent()}
			</div>
		</div>
	)
}

export default TwitterTab