import { useContext } from "react";
import { Icon, Modal, Table, TransitionablePortal } from "semantic-ui-react";

import helpImg from '../../../images/help_functions_landgis.png';
import { AppContext } from "../../AppContext";
import Badge from "./Badge";

function Help(props) {
	const {help, onUpdateState} = useContext(AppContext);

	return (
		<TransitionablePortal open={help} transition={{animation: 'fade up', duration: 500}}>
		<Modal open={help} onClose={() => onUpdateState({help: false})}>
			<Modal.Header style={{borderRadius: 0, color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="background-primary">Help <Icon onClick={() => onUpdateState({help: false})} name="times" style={{color: '#fff', cursor: 'pointer'}} /></Modal.Header>
			<Modal.Content>
				
				<div style={{textAlign: 'center'}}>
					<img src={helpImg} width="70%" />
				</div>
				
				<p>Shortcuts:</p>
				<Table compact unstackable>
					<Table.Body>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Show/hide help <Badge content="Shift ?" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Toggle menu <Badge content="Shift m" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Go to support <Badge content="Shift s" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Go to news <Badge content="Shift n" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
									Toggle geolocation <Badge content="Shift g" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Zoom in <Badge content="Shift +" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Zoom out <Badge content="Shift -" />
							</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Search location <Badge content="/" />
							</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Cycle basemaps <Badge content="Shift b" />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Toggle legend <Badge content="Shift l" />
							</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Add opacity <Badge content="Shift v" />
							</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
								Remove opacity <Badge content="Shift h" />
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Modal.Content>
		</Modal>
		</TransitionablePortal>
	)
}

export default Help;