import { useContext } from "react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";
import Depth from "./Depth";
import Month from "./Month";
import SecondDepth from "./SecondDepth";
import Year from "./Year";

function Slider(props) {
  const context = useContext(AppContext)
  const {layer, layer2, time, time2, depth, depth2, comparison, sidebar, onUpdateState} = context;
  const activeLayer = props.secondary ? layer2 : layer;
  let layerObj = $data.getLayerObject(activeLayer);



  const renderSlider = () => {

    if(layerObj.type === 'horizontal') {
      return <Year secondary={props.secondary} time={props.secondary ? time2 : time} layer={props.secondary ? layer2 : layer} onUpdateState={onUpdateState} />
    }

    if(layerObj.type === 'circular') {
      return <Month secondary={props.secondary} time={props.secondary ? time2 : time}  layer={props.secondary ? layer2 : layer} onUpdateState={onUpdateState} />
    }

    if(layerObj.type === 'vertical') {
      return <Depth secondary={props.secondary} depth={props.secondary ? depth2 : depth} layer={props.secondary ? layer2 : layer} onUpdateState={onUpdateState} />
    }

    if(layerObj.type === 'both') {
      return <>
        <SecondDepth secondary={props.secondary} depth={props.secondary ? depth2 : depth} layer={props.secondary ? layer2 : layer} onUpdateState={onUpdateState} />
        <Year secondary={props.secondary} time={props.secondary ? time2 : time} layer={props.secondary ? layer2 : layer} onUpdateState={onUpdateState} />
      </>
    }

    return null
  }

  return (
    <div className={"olm slider-container" + (layerObj?.type === 'both' ? ' both-dimensions' : '') + (comparison ? ' is-comparison' : '')
      + (layerObj.type === 'horizontal' ? ' year-container' : '') 
      + (layerObj.type === 'vertical' ? ' depth-container' : '') 
      + (layerObj.type === 'circular' ? ' month-container' : '') 
      + (layerObj.type === 'both' ? ' year-container' : '') 
      + (sidebar ? ' sidebar-opened' : '') + (props.secondary ? ' is-second' : '')
      + (comparison ? ' is-comparison' : '')}>
      {renderSlider()}
    </div>
  )

}

export default Slider;