import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import OSM from 'ol/source/OSM';
import URL from "../config/urls";
import $data from "./$data";
import {createXYZ} from 'ol/tilegrid';
import axios from "axios";
import LayerGroup from 'ol/layer/Group';
import Stamen from 'ol/source/Stamen';
import BingSource from 'ol/source/BingMaps';
import XYZ from 'ol/source/XYZ';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import VectorImage from 'ol/layer/VectorImage';
import VectorTile from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import VectorSource from 'ol/source/Vector';
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from 'ol/style/Circle';
import GeoJSON from 'ol/format/GeoJSON';
import MVT from 'ol/format/MVT';
import Feature from "ol/Feature";

let wmsSource = axios.CancelToken.source();


class $map {
	constructor() {
		this.layers = [];
		this.layers2 = [];
		this.keys = [];

	}

	cancelAllWMSRequests() {
		wmsSource.cancel('cancel');
		wmsSource = axios.CancelToken.source();
	}
	
	generateBasemaps(active) {
		this.basemaps = [
			new TileLayer({
				preload: Infinity,
				className: 'ol-layer ol-grayscale',
				name: 'OpenStreetMap',
				visible: active === 'OpenStreetMap',
				zIndex: 1,
				source: new OSM()
			}),
			new LayerGroup({
				name: 'Stamen(OpenStreetMap)',
				visible: active === 'Stamen(OpenStreetMap)',
				zIndex: 3,
				layers: [
					new TileLayer({
						preload: Infinity,
						zIndex: 1,
						source: new Stamen({
							layer: 'toner-background',
						}),
					}),
					new TileLayer({
						preload: Infinity,
						zIndex: 3,
						source: new Stamen({
							layer: 'toner-lines',
						}),
					}),
					new TileLayer({
						preload: Infinity,
						zIndex: 3,
						source: new Stamen({
							layer: 'toner-labels',
						}),
					}),
				]
			})
			,
			new TileLayer({
				preload: Infinity,
				name: 'OpenTopoMap',
				className: "ol-layer ol-grayscale",
				visible: active === 'OpenTopoMap',
				zIndex: 1,
				source: new XYZ({
					url: 'https://a.tile.opentopomap.org/{z}/{x}/{y}.png',
					crossOrigin: 'anonymous'
				}),
			}),
			new TileLayer({
				preload: Infinity,
				name: 'BingMaps(Aerial)',
				visible: active === 'BingMaps(Aerial)',
				zIndex: 1,
				source: new BingSource({
					maxZoom: 19,
					transition: 250,
					key: 'Am5pr8Q-dkmubEMkSwAsIfSkH8UrJjTHt-rMwQUfjjaG5ioU5prI9XSVfT9hjA8G',
					imagerySet: 'AerialWithLabels',
				}),
			}),

		];
		
		return this.basemaps;
	}

	generateLayers(active, opacity, time, depth, isComparison) {

		let layers = $data.layers.map(obj => {
			// if(obj.shortName === 'pnv_biome.type_biome00k_c') {
			// 	return new LayerGroup({
			// 		preload: Infinity,
			// 		name: obj.shortName,
			// 		isComparison: isComparison ? true : false,
			// 		visible: active === obj && !isComparison,
			// 		zIndex: 2,
			// 		isLayerGroup: true,
			// 		layers: [
			// 			new TileLayer({
			// 				preload: Infinity,
			// 				zIndex: 2,
			// 				opacity: parseFloat(opacity)/100,
			// 				source: new TileWMS({
			// 					url: obj.isNewVersion ? URL.GEOSERVER_BASE + 'wms/' : URL.OLD_GEOSERVER + 'wms/',
			// 					serverType: 'geoserver',
			// 					tileLoadFunction: (tile, src) => {
			// 						axios.get(src, {responseType: 'blob', cancelToken: wmsSource.token})
			// 							.then(result => {
			// 								if(result.data !== undefined)  tile.getImage().src = window.URL.createObjectURL(result.data);
			// 								else tile.getImage().src = '';
			// 							})
			// 							.catch(err => { tile.getImage().src = ''; });
			// 					},					
			// 					params: {
			// 						// TILED: true,
			// 						LAYERS: obj.name,
			// 						...this.formatParams(obj, obj.type === 'vertical' ? depth : time)
			// 					}
			// 				})
							
			// 			}),
			// 			new TileLayer({
			// 				zIndex: 4,
			// 				name: obj.shortName + '_3D',
			// 				preload: Infinity,
			// 				visible: false,
			// 				source: new TileWMS({
			// 					url: URL.GEOSERVER_BASE + 'wms/',
			// 					params: {
			// 						// TILED: true,
			// 						LAYERS: 'points:biome00k_points' ,
			// 					},
							
			// 				}),
			// 			}),

			// 			new TileLayer({
			// 				zIndex: 4,
			// 				name: obj.shortName + '_2D',
			// 				preload: Infinity,
			// 				visible: false,
			// 				source: new TileWMS({
			// 					url: URL.GEOSERVER_BASE + 'wms/',
			// 					params: {
			// 						// TILED: true,
			// 						LAYERS: 'points:biome00k_points' ,
			// 					},
							
			// 				}),
			// 			}),
			// 		]
			// 	})
			// }

			// if(obj.shortName === 'sol_grtgroup_usda.soiltax_c') {
			// 	return new LayerGroup({
			// 		preload: Infinity,
			// 		name: obj.shortName,
			// 		visible: active === obj && !isComparison,
			// 		zIndex: 2,
			// 		isLayerGroup: true,
			// 		layers: [
			// 			new TileLayer({
			// 				preload: Infinity,
			// 				zIndex: 2,
			// 				opacity: parseFloat(opacity)/100,
			// 				source: new TileWMS({
			// 					url: URL.GEOSERVER_BASE + 'wms/',
			// 					tileLoadFunction: (tile, src) => {
			// 						axios.get(src, {responseType: 'blob', cancelToken: wmsSource.token})
			// 							.then(result => {
			// 								if(result.data !== undefined)  tile.getImage().src = window.URL.createObjectURL(result.data);
			// 								else tile.getImage().src = '';
			// 							})
			// 							.catch(err => { tile.getImage().src = ''; });
			// 					},					
			// 					params: {
			// 						TILED: true,
			// 						LAYERS: obj.name,
			// 						...this.formatParams(obj, obj.type === 'vertical' ? depth : time)
			// 					}
			// 				})
							
			// 			}),
			// 			new TileLayer({
			// 				zIndex: 4,
			// 				name: obj.shortName + '_3D',
			// 				preload: Infinity,
			// 				visible: false,
			// 				source: new TileWMS({
			// 					url: URL.GEOSERVER_BASE + 'wms/',
			// 					params: {
			// 						// TILED: true,
			// 						LAYERS: 'points:training_points_sol_grtgroup_usda.soiltax_c' ,
			// 					},
							
			// 				}),
			// 			}),
			// 			new TileLayer({
			// 				zIndex: 4,
			// 				name: obj.shortName + '_2D',
			// 				preload: Infinity,
			// 				visible: false,
			// 				source: new TileWMS({
			// 					url: URL.GEOSERVER_BASE + 'wms/',
			// 					params: {
			// 						// TILED: true,
			// 						LAYERS: 'points:training_points_sol_grtgroup_usda.soiltax_c' ,
			// 					},
							
			// 				}),
			// 			}),
			// 		]
			// 	})
			// }

			return new TileLayer({
				preload: Infinity,
				name: obj.shortName,
				opacity: parseFloat(opacity)/100,
				visible: active === obj.shortName && !isComparison,
				zIndex: 2,
				isLayerGroup: false,
				
				source: new TileWMS({
					transition: 250,
					cacheSize: 0,
					tileGrid: createXYZ({tileSize: 256}),
					interpolate: false,
					// url: obj.isNewVersion ? URL.GEOSERVER_BASE + `${obj.name.indexOf('oem') > -1 ? 'oem' : 'olm'}/wms/` : URL.OLD_GEOSERVER + 'wms/',
					url: URL.GEOSERVER_BASE + 'wms/',
					tileLoadFunction: (tile, src) => {
						axios.get(src, {responseType: 'blob', cancelToken: wmsSource.token})
							.then(result => {
								if(result.data !== undefined)  tile.getImage().src = window.URL.createObjectURL(result.data);
								else tile.getImage().src = '';
							})
							.catch(err => { tile.getImage().src = ''; });
					},					
					params: {
						// TILED: true,
						LAYERS: obj.name,
						...this.formatParams(obj, obj.type === 'vertical' ? depth : time, isComparison)
					}
				})
				
			})
		});

		if(isComparison) {
			this.layers2 = layers;
		} else {
			this.layers = layers;
		}

		return isComparison ? this.layers2 : this.layers;
	}

	toggle3DPoints(bool, layer) {
		try {
			let layerGroup = this.layers.filter(l => l.get('name') === layer)[0]

			if(layerGroup) {
				let baseName = layerGroup.get('name')
	
				layerGroup.getLayers().getArray().map(l => {
					if(l.get('name') === baseName + '_3D') {
						l.setVisible(bool)
					}
	
					if(l.get('name') === baseName + '_2D') {
						l.setVisible(!bool);
					}
	
	
				})
			}
		} catch(e) {

		}
	

	}

	toggle3DPoints2(bool, layer) {
		try {
			let layerGroup = this.layers2.filter(l => l.get('name') === layer)[0]

			if(layerGroup) {
				let baseName = layerGroup.get('name')
	
				layerGroup.getLayers().getArray().map(l => {
					if(l.get('name') === baseName + '_3D') {
						l.setVisible(bool)
					}
	
					if(l.get('name') === baseName + '_2D') {
						l.setVisible(!bool);
					}
	
	
				})
			}
		} catch(e) {

		}
	

	}

	formatParams(layerObj, dimension, isComparison) {
		if(layerObj.type === 'single') return {};
		return {
			[layerObj.paramGSName + (isComparison ? '2' : '')]: dimension ? dimension : layerObj.paramDefaultValue
		}
	}

	formatDualParams(layerObj, time, depth, isComparison) {
		if(isComparison) {
			return {
				[layerObj.paramGSName + '2']: time ? time : layerObj.paramDefaultValue,
				[layerObj.paramGSName2 + '2']: depth ? depth : layerObj.paramDefaultValue2,
			}
		}

		return {
			[layerObj.paramGSName]: time ? time : layerObj.paramDefaultValue,
			[layerObj.paramGSName2]: depth ? depth : layerObj.paramDefaultValue2,
		}
	}

	setBase(base) {
		this.basemaps.map(b => {
			if(b.get('name') === base) {
				b.setVisible(true);
			} else {
				b.setVisible(false)
			}
		})
	}

	setLayer(layer, dimension, both) {
		let layerObj = $data.getLayerObject(layer);
		this.layers.map(l => {
			if(l.get('name') === layer) {
				this.cancelAllWMSRequests();
				if(layerObj?.type !== 'single' && layerObj?.type !== 'both') {
					l.getSource().updateParams(this.formatParams($data.getLayerObject(layer), dimension));
				}

				if(layerObj?.type === 'both') {
					l.getSource().updateParams(this.formatDualParams($data.getLayerObject(layer), both.time, both.depth));

				}
				l.setVisible(true);
			} else {
				l.setVisible(false)
			}
		})
	}

	setLayer2(layer, dimension, isActive, both) {
		let layerObj = $data.getLayerObject(layer);
		this.layers2.map(l => {
			if(l.get('name') === layer) {
				this.cancelAllWMSRequests();
				if(layerObj?.type !== 'single' && layerObj?.type !== 'both') {
					l.getSource().updateParams(this.formatParams($data.getLayerObject(layer), dimension, true));
				}
				
				if(layerObj?.type === 'both') {
					l.getSource().updateParams(this.formatDualParams($data.getLayerObject(layer), both.time, both.depth, true));

				}

				l.setVisible(isActive ? true : false);
			} else {
				l.setVisible(false)
			}
		})
	}

	hideComparisonLayers() {
		this.layers2.map(l => l.setVisible(false))
	}

	setOpacity(opacity) {
		this.layers.filter(l => {
			if(l.get('isLayerGroup')) {
				l.getLayers().getArray()[0].setOpacity(parseFloat(opacity)/100);
			} else {
				l.setOpacity(parseFloat(opacity)/100);
			}
		});
		this.layers2.filter(l => {
			if(l.get('isLayerGroup')) {
				l.getLayers().getArray()[0].setOpacity(parseFloat(opacity)/100);
			} else {
				l.setOpacity(parseFloat(opacity)/100);
			}
		});		
	}

	updateDimension(dimension, layer) {
		let layerInstance = this.layers.filter(l => l.get('name') === layer)[0];

		if(layerInstance) {
			layerInstance.getSource().updateParams(this.formatParams($data.getLayerObject(layer), dimension))
		}
	}

	updateDimension2(dimension, layer) {
		let layerInstance = this.layers2.filter(l => l.get('name') === layer)[0];

		if(layerInstance) {
			layerInstance.getSource().updateParams(this.formatParams($data.getLayerObject(layer), dimension))
		}
	}

	updateDualDimension(time, depth, layer) {
		let layerInstance = this.layers.filter(l => l.get('name') === layer)[0];

		if(layerInstance) {
			layerInstance.getSource().updateParams(this.formatDualParams($data.getLayerObject(layer), time, depth))
		}
	}

	updateDualDimension2(time, depth, layer) {
		let layerInstance = this.layers2.filter(l => l.get('name') === layer)[0];

		if(layerInstance) {
			layerInstance.getSource().updateParams(this.formatDualParams($data.getLayerObject(layer), time, depth))
		}
	}

	isGFIQuery(layer, is3D) {
		// if(!is3D) return false;
		let group = this.layers.filter(l => l.get('name') === layer)[0];
		
		if(!group) return false;

		try {
			return group.getLayers().getArray()[2];
		} catch(e) {
			return false
		}
	}

	zoomToPoint(point, isGeolocation) {
		if(point && this.instance) {
			
			this.instance.getView().animate({center: point, duration: 500}, {zoom: 13, duration: 500});
			
			if(isGeolocation) {
				this.geoPin.setPosition(point);
				return
			}

			setTimeout(() => {
				this.pin.setPosition(point)
			}, 1100)
		}

	}

	zoomIn() {
		if(this.instance) {
			let currentZoom = this.instance.getView().getZoom();
			if(currentZoom + 0.5 <= 20) {
				this.instance.getView().animate({zoom: currentZoom + 0.5, duration: 250})
			}
		}
		
	}

	zoomOut() {
		if(this.instance) {
			let currentZoom = this.instance.getView().getZoom();
			if(currentZoom - 0.5 >= 1) {
				this.instance.getView().animate({zoom: currentZoom - 0.5, duration: 250})
			}

		}
	}
}

export default new $map();