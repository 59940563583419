import _, { debounce } from "lodash";
import { fromLonLat } from "ol/proj";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Icon, Input, Search } from "semantic-ui-react";
import oghLogo from '../../images/logo-white2.png'
import $data from "../../services/$data";
import $map from "../../services/$map";
import { AppContext } from "../AppContext";
function SearchLocation(props) {
	const {onUpdateState, geolocation} = useContext(AppContext);

	const [state, setState] = useState({loading: false, query: '', value: '', results: []})

	const onChange = (evt, {value}) => {
		if(value === '') {
			setState({query: value, value, loading: false})
		} else {
			setState({query: value, value, loading: true});
		}
	}

	const isLatLon = (str) => {
		let regex = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/);

		let isLatLon = str.match(regex);

		if(isLatLon) {
			let split = str.split(',').map(v => parseFloat(v.trim())).reverse();

			return split;
		}

		return null

	}


	const getLocations =  _.debounce(() => {
		if(!state.query) return;
		
		let isLtlng = isLatLon(state.query);
		if(isLtlng) {
			$data.getLocationsReverse(isLtlng)
				.then(data => {
					setState({loading: false, results: data})
				})
				.catch(err => {

				})
		} else {
			$data.getLocations(state.query)
				.then(data => {
					setState({loading: false, results: data})
				})
				.catch(err => {
					console.log(err)
				})
		}

		
	}, 300)

	useEffect(() => {
		if(state.query !== '') {
			
			getLocations()
		}

		return getLocations.cancel
	}, [state.query])

	const navigateToLocation = (evt, {result}) => {
		$map.zoomToPoint(result.coordinates)
	}

	return (
		<div className="olm search-location-container">
			<div className="search-input-container">
				<div onClick={() => onUpdateState({about: true})} style={{cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginRight: '5px'}}>
					<img src="/assets/openlandmap_logo_w.png" width="136" />
					{/* <span className="logo-text" style={{lineHeight: '40px', paddingBottom: '4px'}}>OpenLandMap</span> */}
				</div>
				<Search 
					className="search-location-focus"
					value={state.value} 
					onSearchChange={onChange} 
					loading={state.loading} 
					placeholder="Search OpenStreetMap..." 
					onResultSelect={navigateToLocation}
					icon={'search'}
					results={state.results}/>
			</div>
			<Icon onClick={() => onUpdateState({geolocation: !geolocation})} style={{color: geolocation ? 'green' : "#fff"}} name="location arrow" />
		</div>
	)
}

export default SearchLocation;