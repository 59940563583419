import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/main.scss';
import $interceptor from './services/$interceptor';

const root = ReactDOM.createRoot(document.getElementById('root'));

$interceptor.init();

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

reportWebVitals();
