import CircularSlider from "@fseehawer/react-circular-slider";
import { memo, useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";

function Month(props) {
  const {animation, onUpdateState} = useContext(AppContext);
  const layerObj = $data.getLayerObject(props.layer);

  const renderLabel = () => {
    return (
      <div className="month-label-container">
        {animation === false && <Button onClick={() => onUpdateState({animation: true})} icon="play"></Button>}
        {animation === true && <Button onClick={() => onUpdateState({animation: false})} icon="pause"></Button>}
        <label>{layerObj.rangeDisplay[layerObj.range.indexOf(props.time)]}</label>
      </div>
    )
  }

  const renderMobile = () => {

    return (
      <div className="month-slider-component-mobile">
        {/* <Button onClick={() => props.onUpdateState({[props.secondary ? 'time2' : 'time']: layerObj.range[11]})} className={props.time === layerObj.range[11] ? 'active' : ''} size="tiny">{layerObj.range[11]}</Button> */}
        {layerObj.rangeDisplay.map((name, key) => {          // if(key === 11) return null;

          // if(key === 11) return null;
          return <Button onClick={() => props.onUpdateState({[props.secondary ? 'time2' : 'time']: layerObj.range[key]})}  className={props.time === layerObj.range[key] ? 'active' : ''} size="tiny" key={key}>{name.slice(0, 3)}</Button>
        })}
      </div>
    )
  }

  return (
    <>
      {renderMobile()}
      <div className="month-slider-component">
        <CircularSlider
          width={120}
          height={120}
          data={layerObj.range}
          label={layerObj.rangeDisplay[layerObj.range.indexOf(props.time)]}
          dataIndex={useMemo(() => layerObj.range.indexOf(props.time), [props.time])}
          trackColor={'#34495e'}
          labelFontSize="11"
          progressColorFrom={'#34495e'}
          progressColorTo={'#34495e'}
          renderLabelValue={renderLabel()}
          knobColor="#46637F"
          knobPosition="top"
          onChange={value => onUpdateState({[props.secondary ? 'time2' : 'time']: value})}
        />
      </div>
     </>
  )
}

export default memo(Month, (prevProps, nextProps) => {
  return prevProps.time === nextProps.time
});