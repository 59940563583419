import { Range } from "react-range";
import { Button, Icon } from "semantic-ui-react";
import $data from "../../services/$data";
import RangeSlider from 'rc-slider';
import { useContext } from "react";
import { AppContext } from "../AppContext";

function Year(props) {
  const {animation, comparison} = useContext(AppContext)
  const layerObj = $data.getLayerObject(props.layer);


  let index = layerObj.range.indexOf(props.time);

  let marks = {};

  layerObj.range.map((v, i) => {
    if(i === 0 || i === layerObj.range.length - 1) {
      marks[i] = layerObj.rangeDisplay[i]
    } else {
      marks[i] = layerObj.rangeDisplay[i]
    }
  });

  const getNext = () => {
    props.onUpdateState({[props.secondary ? 'time2' : 'time']: layerObj.range[index + 1]})
  }

  const getPrev = () => {
    props.onUpdateState({[props.secondary ? 'time2' : 'time']: layerObj.range[index - 1]})

  }

  const renderMobile = () => {
    return (
      <div className="year-slider-component-mobile">
        <Button disabled={index === 0} onClick={getPrev} size="tiny"><Icon name="chevron left" /></Button>
        <div className="value-container">
          {layerObj.rangeDisplay[index]}
        </div>
        <Button disabled={index === layerObj.range.length - 1} onClick={getNext} size="tiny"><Icon name="chevron right" /></Button>
      </div>
    )
  }

  return (
    <>
      {renderMobile()}
      <div className="year-slider-component">
        <div style={{position: 'relative'}}>
          <div className="year-label" >
            {animation === false && !comparison && <Button onClick={() => props.onUpdateState({animation: true})} icon="play"></Button>}
            {animation === true && !comparison && <Button onClick={() => props.onUpdateState({animation: false})} icon="pause"></Button>}
            <div className="year-label-text">
              {layerObj.rangeDisplay[index]}
            </div>
          </div>
        </div>
        <RangeSlider 
          min={0}
          step={1}
          marks={marks}
          max={layerObj.range.length - 1}
          value={index}
          onChange={(value) => {

            props.onUpdateState({[props.secondary ? 'time2' : 'time']: layerObj.range[value]})}
          }/>

      </div>
    </>
  )
}

export default Year;