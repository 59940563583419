import { Icon } from "semantic-ui-react";
import links from "../config/links";

function Footer(props) {
	return (
		<footer style={{padding: '0 5px', position: "absolute", bottom: 0, right: 0, background: 'rgba(255,255,255, .8'}}>
			<a style={{margin: '0 5px', fontWeight: 'bold'}} className="text-primary" target="_blank" href={links.aboutOLM}>&copy; OpenLandMap contributors</a>
			<a style={{margin: '0 5px', fontWeight: 'bold'}} className="text-primary" target="_blank" href={'https://openlandmap.github.io/book'}><Icon name="book" /> About OpenLandMap</a>
		</footer>
	)
}

export default Footer;

