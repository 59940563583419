import { fromLonLat } from 'ol/proj';
import { useContext, useRef } from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import $map from '../../services/$map';
import { AppContext } from '../AppContext';

import kml from '../../images/google-earth.png';

function Controls(props) {
	const {cesium, comparison, onUpdateState} = useContext(AppContext);

	const downloadLink = useRef(null);

	const zoomIn = () => { $map.zoomIn() }

	const zoomOut = () => { $map.zoomOut() }

	const isMobile = window.innerWidth < 768;

	const zoomToExtent = () => {
		if($map.instance) {
			$map.instance.getView().animate({zoom: 2, center: fromLonLat([5,25])})
		}
	}

	const reloadDefault = () => {
		window.location = window.location.href.split('?')[0];
		// window.location.reload();
	}
	
	const toggle3DView = (bool) => {
		onUpdateState({cesium: bool, comparison: bool ? false : comparison})
	}

	const toggleCompare = (bool) => {
		if(!isMobile) {
			onUpdateState({comparison: bool, cesium: bool ? false : cesium})
		}
	}

	const downloadKML = () => {
		downloadLink.current.click();
	}

	return (
		<div className="olm controls-container">
			<Popup position='left center' content="Go to default view" trigger={<div onClick={reloadDefault} className="control control-btn">
				<Icon name="home" />
			</div>} />
			<Popup position='left center' content="Zoom to extent" trigger={<div onClick={zoomToExtent} className="control control-btn">
				<Icon name="expand" />
			</div>} />
			<Popup position='left center' content="Zoom in" trigger={<div onClick={zoomIn} className="control control-btn">
				<Icon name="plus" />
			</div>} />
			<Popup position='left center' content="Zoom out" trigger={<div onClick={zoomOut} className="control control-btn">
				<Icon name="minus" />
			</div>} />
			<Popup position='left center' content={cesium ? '2D view' :'3D view'} trigger={<div onClick={() => toggle3DView(!cesium)} className="control control-btn">
				{!cesium && <Icon name="globe" />}
				{cesium && <Icon style={{transform: 'perspective(20px) rotate3D(1, 0, 0, 30deg)'}} name="grid layout" />}
			</div>} />
			{!comparison && !isMobile && <Popup position='left center' content={"Turn on comparison"} trigger={<div onClick={() => toggleCompare(true)} className="control control-btn">
				<Icon name="columns" />
			</div>} />}
			{comparison && !isMobile &&  <Popup position='left center' content="Turn off comparison" trigger={<div onClick={() => toggleCompare(false)} className="control control-btn">
				<Icon name="square outline" />
			</div>} />}
			{/* <Popup position='left center' content="Download KML" trigger={<div onClick={downloadKML} className="control control-btn last-control">
				<img src={kml} width="25x" style={{padding: 0}} />
			</div>} /> */}
			{/* <a href="https://gitlab.com/openlandmap/global-layers/-/raw/master/KML/LandGIS.kmz" style={{visibility: 'hidden'}} download="OpenLandMapKML.kmz" ref={downloadLink}></a> */}
		</div>
	)
}

export default Controls;