import axios from "axios";
import moment from "moment";

class $query {
  getFeatureInfo(url) {
    return axios
      .get(url)
      .then((result) => {
        if (result.data.features.length === 0) return null;

        return result.data.features.map((f) => f.properties);
      })
      .catch((err) => {
        throw err;
      });
  }

  transformValue(val, func) {
    try {
      let f = new Function("x", `return ${func}`);
      return f(val);
    } catch (e) {
      console.log(e);
      return val;
    }
  }

  sortHorizontal(a, b) {
    let splitA = Object.keys(a)[0].split("_")[5];
    let splitB = Object.keys(b)[0].split("_")[5];

    return parseInt(splitA) - parseInt(splitB);
  }

	sortCircular(a, b) {
		let splitA = Object.keys(a)[0].split("_")[1].split('.');
    let splitB = Object.keys(b)[0].split("_")[1].split('.');

		let sA = splitA[splitA.length - 1].split('m')[1];
		let sB = splitB[splitB.length - 1].split('m')[1];
		return parseInt(sA) - parseInt(sB);
	}

	sortVertical(a, b) {
		let splitA = Object.keys(a)[0].split("_")[4].slice(1, Object.keys(a)[0].split("_")[4].length).split('cm')[0];
    let splitB = Object.keys(b)[0].split("_")[4].slice(1, Object.keys(b)[0].split("_")[4].length).split('cm')[0];

		return parseInt(splitA) - parseInt(splitB);
	}


  get(url, layerObj, transform) {
		console.log(layerObj)
    return axios
      .get(url)
      .then((result) => {
        console.log(result.data);
        let { type, transform_function } = layerObj;
        if (!result.data) return [];

        return result.data
          .sort((a, b) => {
            if(layerObj.type === 'horizontal') {
							return this.sortHorizontal(a, b);
						}

						if(layerObj.type === 'circular') {
							return this.sortCircular(a, b);
						}

						if(layerObj.type === 'vertical') {
							return this.sortVertical(a, b);
						}

						return 0;
          })
          .map((obj) => {
						if(layerObj.type === 'horizontal') {
							let split = Object.keys(obj)[0].split("_");
							let date =
								layerObj.rangeDisplay[
									layerObj.range.indexOf(split[5] + "_" + split[6])
								];
							console.log(date);
							return {
								x: date,
								y: Object.values(obj)[0],
							};	
						}

						if(layerObj.type === 'circular') {
							let split = Object.keys(obj)[0].split("_");
							let date =
								layerObj.rangeDisplay[
									layerObj.range.indexOf(split[1].split('.')[split[1].split('.').length - 1])
								];
							return {
								x: date,
								y: Object.values(obj)[0],
							};
	
						}

						if(layerObj.type === 'vertical') {
							let split = Object.keys(obj)[0].split("_");
							let depth = layerObj.rangeDisplay[
								layerObj.range.indexOf(split[4])
							];

							return {
								x: depth,
								y: Object.values(obj)[0],
							};
						}

						if(layerObj.type === 'both') {
							let split = Object.keys(obj)[0].split('_');
							let date =
							layerObj.rangeDisplay[
								layerObj.range.indexOf(split[5] + "_" + split[6])
							];
							let xVal = split[4] === 's' ? `${date}(0cm)` : `${date}(30cm)`

							return {
								x: xVal,
								y: Object.values(obj)[0]
							}
						}

						return {
							x: 'Value',
							y: Object.values(obj)[0]
						}

						
           });

        if (
          type === "horizontal" ||
          type === "circular" ||
          type === "vertical" ||
          type === "both"
        ) {
          let list = Object.keys(result.data.response[0]).filter(
            (key) => key !== "lat" && key !== "lon"
          );
          if (type === "vertical") {
            list = list.sort((a, b) => {
              let first = a.split("_")[4];
              let second = b.split("_")[4];

              return (
                parseInt(first.split("b")[1].split("_")[0].split("cm")[0]) -
                parseInt(second.split("b")[1].split("_")[0].split("cm")[0])
              );
            });
          }

          if (type === "circular") {
            let copy = Object.assign({}, { ...result.data.response[0] });
            delete copy.lat;
            delete copy.lon;

            return Object.keys(copy)
              .sort((a, b) => {
                let first = a.split("_")[1].split(".")[1];
                let second = b.split("_")[1].split(".")[1];
                let findex = layerObj.range.indexOf(first);
                let sindex = layerObj.range.indexOf(second);
                return findex - sindex;
              })
              .map((name, i) => {
                return {
                  x: layerObj.rangeDisplay[i],
                  y: copy[name],
                };
              });
          }

          return list
            .map((key, i) => {
              let timeindex =
                type !== "vertical"
                  ? layerObj.range.indexOf(key.match(/[0-9]{8}_[0-9]{8}/)[0])
                  : layerObj.range.indexOf(key.split("_")[4]);

              let time = layerObj.rangeDisplay[timeindex];
              let split =
                Object.keys(result.data.response[0]).filter(
                  (key) => key !== "lat" && key !== "lon"
                ).length / 2;
              let depth = null;

              if (type === "both") {
                if (i < split) {
                  depth = layerObj.rangeDisplay2[1];
                } else {
                  depth = layerObj.rangeDisplay2[0];
                }
              }

              if (Boolean(time) === false && type !== "vertical") {
                return null;
              }

              return {
                x: time + (depth ? `(${depth})` : ""),
                y: transform_function
                  ? this.transformValue(
                      result.data.response[0][key],
                      transform_function
                    )
                  : result.data.response[0][key],
              };
            })
            .filter((obj) => Boolean(obj));
        }

        if (result.data.info[0] !== "") {
          return Object.keys(result.data.info[0]).map((key) => ({
            x: key,
            y: result.data.info[0][key],
          }));
        }

        return Object.keys(result.data.response[0])
          .filter((key) => key !== "lat" && key !== "lon")
          .map((key) => ({ x: null, y: result.data.response[0][key] }));
      })
      .catch((err) => {
        throw err;
      });
  }
}

export default new $query();
