import bing from '../../images/bing_overview.png'
import osm from '../../images/osm_overview.png'
import stamen from '../../images/stamen.png'
import otm from '../../images/opentopomap_overview.png'
import { Popup } from 'semantic-ui-react'
import { useContext } from 'react'
import { AppContext } from '../AppContext'

function Basemaps(props) {
  const {base, onUpdateState} = useContext(AppContext);

  return (
    <div className="olm basemaps-container">
      <Popup content="OpenTopoMap" trigger={<img style={{filter: 'grayscale(100)'}} onClick={() => onUpdateState({base: 'OpenTopoMap'})} src={otm} className={"basemap-item ol-grayscale" + (base === 'OpenTopoMap' ? ' active': '')} />} />
      <Popup content="BingMaps(Aerial)" trigger={<img onClick={() => onUpdateState({base: 'BingMaps(Aerial)'})} src={bing} className={"basemap-item" + (base === 'BingMaps(Aerial)' ? ' active': '')} />} />
      {/* <Popup content="Stamen(OpenStreetMap)" trigger={<img onClick={() => onUpdateState({base: 'Stamen(OpenStreetMap)'})} src={stamen} className={"basemap-item" + (base === 'Stamen(OpenStreetMap)' ? ' active': '')} />} /> */}
      <Popup content="OpenStreetMap" trigger={<img style={{filter: 'grayscale(1)'}} onClick={() => onUpdateState({base: 'OpenStreetMap'})} src={osm} className={"basemap-item" + (base === 'OpenStreetMap' ? ' active': '')} />} />
    </div>
  )
}

export default Basemaps;