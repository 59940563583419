import { useContext, useEffect } from "react"
import $map from "../services/$map";
import { AppContext } from "./AppContext"

function Content({children}) {
  const {comparison} = useContext(AppContext);

  useEffect(() => {
    if($map.instance) {
      $map.instance.updateSize();
    }
  }, [comparison])

  return (
    <div className={"olm content-container" + (comparison ? ' full-width' : '')}>{children}</div>
  )
}

export default Content