import { useContext } from "react";
import $data from "../../services/$data";
import { AppContext } from "../AppContext";

function SelectedLayer(props) {
  const {layer} = useContext(AppContext);

  const layerObj = $data.getLayerObject(layer) || {};

  return (
    <div className="olm selected-layer-container">
      {layerObj.title || ' - '}
    </div>
  )
}

export default SelectedLayer;