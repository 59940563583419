import moment from "moment";
import links from "../../../config/links";
import TabHeader from "./TabHeader";

function CreditsTab(props) {
	return (
		<div className="olm tabs-container credits-container text-center">
			<TabHeader title="Credits"></TabHeader>
			<div className="tab-content text-left" style={{padding: '10px'}}>
			
				<p><strong>Created and maintained by:</strong></p>
				<ul>
					<li>Data sets and functionality: <a href={links.envirometrix} target="_blank">EnvirometriX Ltd</a>, Wageningen, the Netherlands,</li>
					<li>App development and webservices: <a href={links.gilab} target="_blank">GILab D.O.O</a>, Beograd, Serbia</li>
				</ul>
				<div style={{textAlign: 'center'}}>
					<a href={links.gilab} target="_blank"><img src="/assets/gilab.svg" width="60%" /></a>
				</div>

				<p>OpenLandMap is based on open-source software: <a href={links.geoserver} target="_blank">Geoserver</a>, <a href={links.react} target="_blank">React</a>, <a href={links.rPlumber} target="_blank">plumber R</a> and <a href={links.openlayers} target="_blank">OpenLayers</a>.  Predictions are based on R software for statistical computing and packages: <a href={links.ranger} target="_blank">ranger</a>,  <a href={links.xgboost} target="_blank">xgboost</a>,  <a href={links.caret} target="_blank">caret</a> and  <a href={links.superlearner} target="_blank">SuperLearner</a>.  Software used for spatial analysis includes: <a href={links.gdal} target="_blank">GDAL</a>, <a href={links.saga} target="_blank">SAGA GIS</a>, <a href={links.grass} target="_blank">GRASS GIS</a>, <a href={links.qgis} target="_blank">QGIS</a> and <a href={links.whitebox} target="_blank">WhiteBoxTools</a>.</p>
			
				<p>We are grateful to the <a href={links.landpotential} target="_blank">LandPotential.org</a> and <a href={links.geowiki} target="_blank">Geo-wiki.org</a> projects for important feedback and contributions, and Alessandro Samuel Rosa, Marcos E. Angelini and Guillermo Federico Olmedo for helping with the import and processing of South American soil profiles.</p>

				<p>We are also grateful to <a href={links.spatialEcology} target="_blank">Spatial Ecology</a> and <a href={links.margosa} target="_blank">Margosa Environmental Solutions</a> for contributing global layers of DEM derivatives and hydrological parameters.</p>

				<hr/>

				<div style={{fontSize: '10px', fontStyle: 'italic'}}>
					
					<p><a href={links.usgsGlobal} target="_blank">USGS Global Ecophysiography map</a> is property of the U.S. Department of the Interior, U.S. Geological Survey.</p>

					<p><a href={links.cciLandCover} target="_blank">CCI Land cover time series</a> © ESA Climate Change Initiative — Land Cover led by UCLouvain (2017).</p>

					<p><a href={links.hyde} target="_blank">Historic land use maps HYDE data set</a> is property of Netherlands Environmental Assessment Agency (PBL).</p>

					<p><a href={links.forestLandscapes} target="_blank">The intact forest landscapes</a> is a project supported by University of Maryland, Greenpeace, World Resources Institute, and Transparent World.</p>

					<p><a href={links.earthquakes} target="_blank">Global earthquakes database</a> is a property of U.S. Geological Survey.</p>

					<p><a href={links.modis} target="_blank">MODIS land products</a> are property of Land Processes Distributed Active Archive Center, U.S. Geological Survey.</p>

					<p><a href={links.globalPrecipitation} target="_blank">Global Precipitation Measurement Integrated Multi-satellitE Retrievals for GPM (IMERG)</a> is provided by NASA Goddard Space Flight Center.</p>

					<p><a href={links.globalLandscape} target="_blank">Global landscape degradation degree map 1992-2015</a> is provided by Space Informatics Lab, University of Cincinnati.</p>
				
				</div>

				<div style={{fontSize: '12px', marginTop: '10px'}}>
				<p>Background layers:</p>
				<ul>
					<li><a href={links.opentopomap} target="_blank">OpenTopoMap cartography © OpenTopoMap</a></li>

					<li><a href={links.bing} target="_blank">BingMaps Aerial © {moment().format('YYYY')} Microsoft</a></li>

					<li><a href={links.stamen} target="_blank">Stamen maps © Stamen Design</a></li>

					<li><a href={links.openstreetmap} target="_blank">OpenStreetMap © OpenStreetMap contributors</a></li>
				</ul>
				</div>



			</div>
		</div>
	)
}

export default CreditsTab;