import { useEffect, useLayoutEffect, useRef } from "react";
import AppContext from "./components/AppContext";
import Content from "./components/Content";
import useMergeState from "./components/hooks/useMergeState";
import Legend from "./components/legend/Legend";
import Loader from "./components/Loader";
import Basemaps from "./components/map/Basemaps";
import Map from "./components/map/Map";
import Controls from "./components/map/Controls";
import SearchLocation from "./components/search/SearchLocation";
import Sidebar from "./components/sidebar/Sidebar";
import Slider from "./components/slider/Slider";
import Toolbar from "./components/toolbar/Toolbar";
import defaults from "./config/defaults";
import $data from "./services/$data";
import $params from "./services/$params";
import toast, { Toaster } from "react-hot-toast";
import NoConnection from "./components/helper/NoConnection";
import Help from "./components/sidebar/tabs/Help";
import About from "./components/About";
import SelectedLayer from "./components/map/SelectedLayer";
import $map from "./services/$map";
import Footer from "./components/Footer";
import LayerSelector from "./components/map/LayerSelector";
import Stats from "./components/Stats";

window.Cesium.Ion.defaultAccessToken = process.env.REACT_APP_CESIUM_TOKEN;


function App() {
  let basemaps = ["OpenTopoMap", "BingMaps(Aerial)", "OpenStreetMap"];

  const [state, setState] = useMergeState({
    ...defaults,
    sidebar: window.innerWidth >= 768,
    loading: true,
  });

  const animationRef = useRef(null);
  const mount = useRef(false);
  const helpRef = useRef(false);
  const aboutRef = useRef(false);
  const sidebarRef = useRef(window.innerWidth >= 768);
  const geolocationRef = useRef(false);
  const baseRef = useRef(state.base);
  const legendRef = useRef(state.legend);
  const opacityRef = useRef(state.opacity);

  const backOnline = (evt) => {
    setState({ isOffline: false });
  }

  const offline = (evt) => {
    setState({ isOffline: true });
  }


  const onHandleKey = (evt) => {
    if (evt.keyCode === 191 && evt.shiftKey) setState({ help: !helpRef.current });

    if (evt.keyCode === 65 && evt.shiftKey) setState({ about: !aboutRef.current });

    if (evt.keyCode === 77 && evt.shiftKey) setState({ sidebar: !sidebarRef.current });

    if (evt.keyCode === 83 && evt.shiftKey) setState({ activeTab: 2, sidebar: true });

    if (evt.keyCode === 78 && evt.shiftKey) setState({ activeTab: 3, sidebar: true });

    if (evt.keyCode === 71 && evt.shiftKey) setState({ geolocation: !geolocationRef.current });

    if (evt.keyCode === 191) {
      if (document.querySelector('.input-check-focus input') !== document.activeElement) {
        document.querySelector('.search-location-focus input').focus();
      }
    }

    if (evt.keyCode === 107 && evt.shiftKey) $map.zoomIn();

    if (evt.keyCode === 109 && evt.shiftKey) $map.zoomOut();

    if (evt.keyCode === 66 && evt.shiftKey) {
      let index = basemaps.indexOf(baseRef.current);

      if (index === basemaps.length - 1) {
        setState({ base: basemaps[0] });
      } else {
        setState({ base: basemaps[index + 1] });
      }
    }

    if (evt.keyCode === 76 && evt.shiftKey) setState({ legend: !legendRef.current });

    if (evt.keyCode === 86 && evt.shiftKey) {
      if (opacityRef.current < 95) { setState({ opacity: opacityRef.current + 5 }); }
      else { setState({ opacity: 100 }) }
    };

    if (evt.keyCode === 72 && evt.shiftKey) {
      if (opacityRef.current > 5) { setState({ opacity: opacityRef.current - 5 }); }
      else { setState({ opacity: 0 }) }

    };


  }


  useEffect(() => {
    mount.current = true;

    window.addEventListener('online', backOnline);

    window.addEventListener('offline', offline);

    window.addEventListener('keyup', onHandleKey);

    $data.getLayers()
      .then(data => { setState({ ...$params.init(window.location.search), loading: false }) })
      .catch(err => {
        $data.getLayers()
          .then(data => { setState({ ...$params.init(window.location.search), loading: false }) })
          .catch(err => {
            $data.getLayers()
              .then(data => { setState({ ...$params.init(window.location.search), loading: false }) })
              .catch(err => { setState({ ...$params.init(window.location.search), loading: false }); })
          })
      })

    return () => {
      $data.cancelLayers();
      $data.cancelQuery();
      toast.dismiss();
      mount.current = false;
      window.removeEventListener('online', backOnline);
      window.removeEventListener('offline', offline);
      window.removeEventListener('keyup', onHandleKey);
    }
  }, []);

  useEffect(() => {
    if (state.loading === false) {
      $params.updateParams({
        base: state.base,
        layer: state.layer,
        opacity: state.opacity,
        zoom: state.zoom,
        center: state.center,
        time: state.time,
        depth: state.depth
      })
    }
  }, [state.base, state.layer, state.opacity, state.zoom, JSON.stringify(state.center), state.time, state.depth])

  useEffect(() => { helpRef.current = state.help; }, [state.help])
  useEffect(() => { aboutRef.current = state.about; }, [state.about])
  useEffect(() => { sidebarRef.current = state.sidebar; }, [state.sidebar])
  useEffect(() => { geolocationRef.current = state.geolocation; }, [state.geolocation])
  useEffect(() => { baseRef.current = state.base; }, [state.base]);
  useEffect(() => { legendRef.current = state.legend; }, [state.legend]);
  useEffect(() => { opacityRef.current = state.opacity; }, [state.opacity]);

  useEffect(() => {
    if (state.animation) {
      startSliderAnimation();
    } else {
      pauseSliderAnimation();
    }
  }, [state.animation])


  useLayoutEffect(() => {
    if (!state.isOffline && mount.current) {
      toast.success('You are back online!', { duration: 8000, style: { background: 'green', color: '#fff', fontWeight: 'bold' } })
    }
  }, [state.isOffline])

  const startSliderAnimation = (isForward) => {
    let layerObj = $data.getLayerObject(state.layer);
    let { range } = layerObj;

    let index = range.indexOf(state[layerObj.paramDisplayName]);

    if (index === range.length - 1) {
      index = 0;
      setState({ [layerObj.paramDisplayName]: range[0] });
    }

    animationRef.current = setInterval(() => {
      if (index === range.length - 1) {
        setState({ animation: false })
        return;
      }
      setState({ [layerObj.paramDisplayName]: range[index + 1] })
      index += 1
    }, 1000)
  }

  const pauseSliderAnimation = () => {
    clearInterval(animationRef.current)
  }

  if (state.loading) {
    return <Loader active={state.loading} />
  }

  let online = true;

  if (window.navigator) {
    if (!window.navigator.onLine) {
      online = false;
    }
  }

  return (
    <AppContext value={{ ...state, onUpdateState: setState }}>
      <div><Toaster position="top-center" /></div>
      {(!online || state.isOffline) && <NoConnection show={true} />}
      <Content>
        <Map />
        <Controls />
        <Legend />
        {state.comparison && <Legend secondary />}
        <Basemaps />
        <Slider />
        {state.comparison && <Slider secondary />}
        <SearchLocation />
        {!state.comparison && <SelectedLayer />}

        {state.comparison && <LayerSelector />}
        {state.comparison && <LayerSelector secondary />}

        <Footer />
      </Content>
      {!state.comparison && <>
        <Sidebar />
        <Toolbar />
      </>}
      <Help />
      <About />
      {state.stats && <Stats />}
    </AppContext>
  );
}

export default App;
