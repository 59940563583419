import { useRef, useState } from "react";
import { Icon, Loader, Table } from "semantic-ui-react";
import TimeSeries from "../chart/TimeSeries";

function InfoWindow({queryState, layerObj}) {
	const downloadRef = useRef(null);
	const [showChart, setShowChart] = useState(false);

	const renderFeatureRows = (feature = {}) => {
		let rows = [];

		Object.keys(feature)
			.filter(key => key !== 'geometry' && key !== 'geomBBOX' && key !== 'envBBOX'  && key !== 'the_geom')
			.map((key, i) => {
				rows.push(
					<Table.Row key={i}>
						<Table.Cell style={{wordBreak: 'break-all'}}>{key}</Table.Cell>
						<Table.Cell>{feature[key]}</Table.Cell>
					</Table.Row>
				)
			})
		
		return rows	
}

const download = () => {

	let rows = queryState.data.map(obj => [obj.x, obj.y]);
	let header = [
		[queryState.coordinates[1].toString(), queryState.coordinates[0].toString()]
	]
	let csvObj = 'data:text/csv;charset=utf-8,' + header.map(a => a.join(',')) + '\n' + (rows.map(r => r.join(',')).join('\n'));

	let encoded = encodeURI(csvObj);

	downloadRef.current.href = encoded;
	downloadRef.current.download = layerObj.title + '.csv';

	downloadRef.current.click();


}

const toggleChart = () => {
	setShowChart(true);
}

const renderFeatures = (features = []) => {

	return features.map((feature, key) => {
		return (
			<Table key={key} size="small">
				<Table.Body>
					{renderFeatureRows(feature)}
				</Table.Body>
			</Table>
		)	
	})
}

	const renderContent = () => {
		if(queryState.loading) {
			return <Loader active />
		}

		if(queryState.error) {
			return <p className="text-danger text-center">
				Something went wrong while trying to query the location for selected dataset	
			</p>
		}


		if(queryState.features) {
			return renderFeatures(queryState.features);
		}

		if(queryState.data.length === 0) {
			return <p className="text-center">No data for selected location</p>
		}



		let rows = []
		queryState.data.map((obj, key) => {
			rows.push(
				<Table.Row key={key}>
					<Table.Cell>{obj.x || 'Value'}</Table.Cell>
					<Table.Cell>{obj.y} {layerObj.units}</Table.Cell>
				</Table.Row>
			)
		})

		return (
			<Table size="small">
				<Table.Body>
					{rows}
				</Table.Body>
			</Table>
		)
	}

	const renderHeader = () => {
		if(layerObj && queryState.coordinates) {
			return (
				<div className="info-header">
					<p className="text-primary">{layerObj.title}</p>
					<div style={{marginBottom: '10px', display:'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
						<Icon name="map marker alternate" className="text-primary" />
						{queryState?.coordinates[1].toFixed(4)}, {queryState?.coordinates[0].toFixed(4)}
					</div>
					{queryState.feature && <p>Feature</p>}
					{!queryState.loading && <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
					<div style={{cursor: 'pointer'}} className="text-primary" onClick={download}>
						
						<Icon name="download"></Icon>
						CSV
					</div>
					{layerObj.type !== 'single' && <div style={{marginLeft: '10px',cursor: 'pointer'}} className="text-primary" onClick={toggleChart}>
						
						<Icon name="chart bar"></Icon>
						GRAPH
					</div>}
					</div>}
				</div>
			)
		}

		return null
	}


	return (
		<div className="info-window-content">
			{renderHeader()}
			<div style={{display: "flex", flexFlow: 'column', flex: '0 1 auto', overflowX: 'auto'}}>
				{renderContent()}

			</div>

			<a ref={downloadRef}></a>

			{showChart && <TimeSeries open={showChart} onClose={() => setShowChart(false)} data={queryState} layerObj={layerObj} />}
		</div>
	)
}

export default InfoWindow;