import { useContext } from "react";
import { Icon, Label, Modal, Table, TransitionablePortal } from "semantic-ui-react";

function FeatureWindow({features, onClose}) {

  const renderHeader = (features = []) => {
    if(!features) return null;

    if(features.length === 0) return null;

    return Object.keys(features[0]).map((name, key) => {
      return <Table.HeaderCell style={{wordBreak: 'break-word'}} key={key}>{name}</Table.HeaderCell>
    })

  }

  const renderRows = (features = []) => {
    if(!features) return null;
    if(features.length === 0) return null

    return features.map((f, key) => {

      return <Table.Row key={key}>
        {Object.keys(f).map((col, j) => {
          return <Table.Cell style={{wordBreak: 'break-word'}} key={j}>{f[col]}</Table.Cell>
        })}
      </Table.Row>
    })
  }

	return (
		<TransitionablePortal open={features ? true : false} transition={{animation: 'fade up', duration: 500}}>
		<Modal open={features ? true : false} onClose={onClose}>
	
			<Modal.Header style={{borderRadius: 0, color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="background-primary">Feature(s) <Icon onClick={onClose} name="times" style={{color: '#fff', cursor: 'pointer'}} /></Modal.Header>
			
			<Modal.Content>
				
        <Table style={{tableLayout: 'fixed'}} celled striped>
          <Table.Header>
            <Table.Row>
              {renderHeader(features)}
            </Table.Row>
          </Table.Header>
          <Table.Body>
              {renderRows(features)}
          </Table.Body>
        </Table>
			
			
			
			</Modal.Content>
		</Modal>
		</TransitionablePortal>
	)
}

export default FeatureWindow;