import $data from "../services/$data";

const defaults = {
	base: 'OpenStreetMap',
	center: [25, 39],
	zoom: 4,
	opacity: 72,
	// layer: 'lcv_land.cover_esacci.lc.l4_c',
	// layer2: 'lcv_land.cover_esacci.lc.l4_c',
	layer: "lc_glc.fcs30d",
	layer2: "lc_glc.fcs30d",
	
	// time: "2018",
	// depth: '0',
	// time2: "2018",
	// depth2: '0',
	time: "20220101_20221231",
	depth: 'b0cm',
	time2: "20220101_20221231",
	depth2: 'b0cm',

	activeTab: 4,
	legend: true,
	cesium: false,
	comparison: false,
	activeIndex: 1,
	animation: false,
	isOffline: false,
	geolocation: false,
	help: false,
	about: false,
	stats: false,
}

export const themes = {
	'dtm': 'Relief/geology',
	'lcv': 'Land cover, land use and administrative data',
	'pnv': 'Potential natural vegetation',
	'veg': 'Vegetation indices',
	'ldg': 'Land degradation indices',
	'clm': 'Climatic layers',
	'sol': 'Soil properties and classes',
	'hyd': 'Hydrology and water dynamics',
	
}

export const trackParamsNames = ['center', 'zoom', 'opacity', 'base', 'layer', 'time', 'depth'];

export const trackParams = [
	{num: 3, name: 'base', validate: (base) => ['OpenStreetMap', 'BingMaps(Aerial)', 'OpenTopoMap', 'Stamen(OpenStreetMap)']. indexOf(base) > -1 ? base : defaults.base},

	{num: 4, name: 'layer', validate: (name) => {
		let layerObj = $data.getLayerObject(name);

		if(!layerObj?.shortName || layerObj?.shortName !== name || !name) return defaults.layer;

		return layerObj.shortName;
	}},

	{num: 1, name: 'zoom', validate: (zoom) => parseInt(zoom) >= 2 && parseInt(zoom) <= 20 ? zoom : defaults.zoom},

	{num: 0, name: 'center', validate: (value) => {
		if(!value) return defaults.center.join(',')

		if(value.constructor !== Array) { value = value.split(',') }

		value = [parseFloat(value[0]), parseFloat(value[1])];

		if (value[1] > -180 && value[1] < 180 && value[0] > -90 && value[0] < 90) { return value.join(',') }

		else { return defaults.center.join(',')}
	}},
	{num: 2, name: 'opacity', validate: (opacity) => parseFloat(opacity) >= 0 && parseFloat(opacity) <= 100 ? opacity : defaults.opacity},
	{num: 5, name: 'time', validate: (time, layerName, fromURL) => {

		let layerObj = $data.getLayerObject(layerName);
		if(!layerObj || layerObj?.type === 'single' || layerObj?.type === 'vertical') return null;
		let index = -1;

		
		if(fromURL) {

			index = layerObj.rangeDisplay.indexOf(decodeURIComponent(time));
			return index > -1 ? layerObj.range[index] : layerObj.range[layerObj.range.length - 1]; 
		}

		index = layerObj.range.indexOf(time);

		return index > -1 ? layerObj.range[index] : layerObj.range[layerObj.range.length - 1]; 


	}},
	{num: 6, name: 'depth', validate: (depth, layerName, fromURL) => {
		let layerObj = $data.getLayerObject(layerName);
		if(!layerObj || layerObj?.type === 'single' || layerObj?.type === 'horizontal' || layerObj?.type === 'circular') return null;
		
		let index = -1;
	
		if(layerObj?.type === 'both') {
			index = layerObj.rangeDisplay2.indexOf(depth);

			if(fromURL) {
				index = layerObj.rangeDisplay2.indexOf(depth);
	
			} else {
				index = layerObj.range2.indexOf(depth);
	
			}

			return index > -1 ? layerObj.range2[index] : layerObj.range2[0]; 
			
		} else {
			index = layerObj.rangeDisplay.indexOf(depth);

			if(fromURL) {
				index = layerObj.rangeDisplay.indexOf(depth);
	
			} else {
				index = layerObj.range.indexOf(depth);
	
			}
		
			return index > -1 ? layerObj.range[index] : layerObj.range[0]; 
		}

		
	}},
];

export default defaults;